import {
  POST_PAYPAL_NOTIFICATION,
  POST_PAYPAL_NOTIFICATION_SUCCESS,
  POST_PAYPAL_NOTIFICATION_ERROR,
} from '../actions';

const INIT_STATE = {
  error: '',
  loadingPostPaypalNotification: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_PAYPAL_NOTIFICATION:
      return {
        ...state,
        loadingPostPaypalNotification: true,
        error: '',
      };
    case POST_PAYPAL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingPostPaypalNotification: false,
        error: '',
      };
    case POST_PAYPAL_NOTIFICATION_ERROR:
      return {
        ...state,
        loadingPostPaypalNotification: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
