/* eslint-disable import/no-cycle */
/* SETTINGS */

export const SET_CALLBACK_LINK = 'SET_CALLBACK_LINK';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* WEBINARS */

export const WEBINAR_PRESENTER_ADD_ITEM = 'WEBINAR_PRESENTER_ADD_ITEM';
export const WEBINAR_PRESENTER_ADD_ITEM_SUCCESS =
  'WEBINAR_PRESENTER_ADD_ITEM_SUCCESS';
export const WEBINAR_PRESENTER_ADD_ITEM_ERROR =
  'WEBINAR_PRESENTER_ADD_ITEM_ERROR';
export const WEBINAR_GET_PRESENTERS = 'WEBINAR_GET_PRESENTERS';
export const WEBINAR_GET_PRESENTERS_SUCCESS = 'WEBINAR_GET_PRESENTERS_SUCCESS';
export const WEBINAR_GET_PRESENTERS_ERROR = 'WEBINAR_GET_PRESENTERS_ERROR';
export const WEBINAR_GET_LIST = 'WEBINAR_GET_LIST';
export const WEBINAR_GET_LIST_SUCCESS = 'WEBINAR_GET_LIST_SUCCESS';
export const WEBINAR_GET_LIST_ERROR = 'WEBINAR_GET_LIST_ERROR';

/* SUBSCRIBER */
export const SUBSCRIBER_WEBINAR_GET_LIST = 'SUBSCRIBER_WEBINAR_GET_LIST';
export const SUBSCRIBER_WEBINAR_GET_LIST_SUCCESS =
  'SUBSCRIBER_WEBINAR_GET_LIST_SUCCESS';
export const SUBSCRIBER_WEBINAR_GET_LIST_ERROR =
  'SUBSCRIBER_WEBINAR_GET_LIST_ERROR';

export const GET_CERTIFICATE = 'GET_CERTIFICATE';
export const GET_CERTIFICATE_SUCCESS = 'GET_CERTIFICATE_SUCCESS';
export const GET_CERTIFICATE_ERROR = 'GET_CERTIFICATE_ERROR';
export const POST_EMAIL_NOTIFICATION = 'POST_EMAIL_NOTIFICATION';
export const POST_EMAIL_NOTIFICATION_SUCCESS =
  'POST_EMAIL_NOTIFICATION_SUCCESS';
export const POST_EMAIL_NOTIFICATION_ERROR = 'POST_EMAIL_NOTIFICATION_ERROR';

export const WEBINAR_GET_LIST_WITH_FILTER = 'WEBINAR_GET_LIST_WITH_FILTER';
export const WEBINAR_GET_LIST_WITH_ORDER = 'WEBINAR_GET_LIST_WITH_ORDER';
export const WEBINAR_GET_LIST_SEARCH = 'WEBINAR_GET_LIST_SEARCH';
export const WEBINAR_ADD_ITEM = 'WEBINAR_ADD_ITEM';
export const WEBINAR_ADD_ITEM_SUCCESS = 'WEBINAR_ADD_ITEM_SUCCESS';
export const WEBINAR_ADD_ITEM_ERROR = 'WEBINAR_ADD_ITEM_ERROR';
export const WEBINAR_SELECTED_ITEMS_CHANGE = 'WEBINAR_SELECTED_ITEMS_CHANGE';
export const WEBINAR_UPDATE = 'WEBINAR_UPDATE';
export const WEBINAR_UPDATE_SUCCESS = 'WEBINAR_UPDATE_SUCCESS';
export const WEBINAR_UPDATE_ERROR = 'WEBINAR_UPDATE_ERROR';
export const WEBINAR_GET_ITEM = 'WEBINAR_GET_ITEM';
export const WEBINAR_GET_ITEM_SUCCESS = 'WEBINAR_GET_ITEM_SUCCESS';
export const WEBINAR_GET_ITEM_ERROR = 'WEBINAR_GET_ITEM_ERROR';

export const GET_CHECKOUT_LINK = 'GET_CHECKOUT_LINK';
export const GET_CHECKOUT_LINK_SUCCESS = 'GET_CHECKOUT_LINK_SUCCESS';
export const GET_CHECKOUT_LINK_ERROR = 'GET_CHECKOUT_LINK_ERROR';
export const CANCEL_WEBINAR = 'CANCEL_WEBINAR';
export const CANCEL_WEBINAR_SUCCESS = 'CANCEL_WEBINAR_SUCCESS';
export const CANCEL_WEBINAR_ERROR = 'CANCEL_WEBINAR_ERROR';
export const UPDATE_LOGO = 'UPDATE_LOGO';
export const UPDATE_LOGO_SUCCESS = 'UPDATE_LOGO_SUCCESS';
export const UPDATE_LOGO_ERROR = 'UPDATE_LOGO_ERROR';

export const UPDATE_PROMO_IMG = 'UPDATE_PROMO_IMG';
export const UPDATE_PROMO_IMG_SUCCESS = 'UPDATE_PROMO_IMG_SUCCESS';
export const UPDATE_PROMO_IMG_ERROR = 'UPDATE_PROMO_IMG_ERROR';

export const START_WEBINAR = 'START_WEBINAR';
export const START_WEBINAR_SUCCESS = 'START_WEBINAR_SUCCESS';
export const START_WEBINAR_ERROR = 'START_WEBINAR_ERROR';

export const CLOSE_WEBINAR = 'CLOSE_WEBINAR';
export const CLOSE_WEBINAR_SUCCESS = 'CLOSE_WEBINAR_SUCCESS';
export const CLOSE_WEBINAR_ERROR = 'CLOSE_WEBINAR_ERROR';

export const WEBINAR_GET_ITEM_DETAIL = 'WEBINAR_GET_ITEM_DETAIL';
export const WEBINAR_GET_SUBSCRIBERS = 'WEBINAR_GET_SUBSCRIBERS';
export const WEBINAR_GET_SUBSCRIBERS_SUCCESS =
  'WEBINAR_GET_SUBSCRIBERS_SUCCESS';
export const WEBINAR_GET_SUBSCRIBERS_ERROR = 'WEBINAR_GET_SUBSCRIBERS_ERROR';
export const WEBINAR_GET_ITEM_BY_SUBID = 'WEBINAR_GET_ITEM_BY_SUBID';
export const WEBINAR_GET_ITEM_BY_SUBID_SUCCESS =
  'WEBINAR_GET_ITEM_BY_SUBID_SUCCESS';
export const WEBINAR_GET_ITEM_BY_SUBID_ERROR =
  'WEBINAR_GET_ITEM_BY_SUBID_ERROR';

export const WEBINAR_GET_METRICS = 'WEBINAR_GET_METRICS';
export const WEBINAR_GET_METRICS_SUCCESS = 'WEBINAR_GET_METRICS_SUCCESS';
export const WEBINAR_GET_METRICS_ERROR = 'WEBINAR_GET_METRICS_ERROR';

export const GET_METRICS_WEBINAR_ID = 'GET_METRICS_WEBINAR_ID';
export const GET_METRICS_WEBINAR_ID_SUCCESS = 'GET_METRICS_WEBINAR_ID_SUCCESS';
export const GET_METRICS_WEBINAR_ID_ERROR = 'GET_METRICS_WEBINAR_ID_ERROR';

export const WEBINAR_DOWNLOAD_REPORT = 'WEBINAR_DOWNLOAD_REPORT';
export const WEBINAR_DOWNLOAD_REPORT_SUCCESS =
  'WEBINAR_DOWNLOAD_REPORT_SUCCESS';
export const WEBINAR_DOWNLOAD_REPORT_ERROR = 'WEBINAR_DOWNLOAD_REPORT_ERROR';

export const WEBINAR_DELETE_PRESENTER = 'WEBINAR_DELETE_PRESENTER';
export const WEBINAR_DELETE_PRESENTER_SUCCESS =
  'WEBINAR_DELETE_PRESENTER_SUCCESS';
export const WEBINAR_DELETE_PRESENTER_ERROR = 'WEBINAR_DELETE_PRESENTER_ERROR';
export const UPDATE_CERTIFICATE = 'UPDATE_CERTIFICATE';
export const UPDATE_CERTIFICATE_SUCCESS = 'UPDATE_CERTIFICATE_SUCCESS';
export const UPDATE_CERTIFICATE_ERROR = 'UPDATE_CERTIFICATE_ERROR';

/* CHAT APP */
export const SET_WSCONNECTION = 'SET_WSCONNECTION';
export const CHAT_SEND_MESSAGE = 'CHAT_SEND_MESSAGE';
export const CHAT_SEND_MESSAGE_SUCCESS = 'CHAT_SEND_MESSAGE_SUCCESS';
export const CHAT_SEND_MESSAGE_ERROR = 'CHAT_SEND_MESSAGE_ERROR';
export const CHAT_ADD_MESSAGE = 'CHAT_ADD_MESSAGE';
export const CHAT_ADD_SURVEY = 'CHAT_ADD_SURVEY';
export const CHAT_ADD_QUESTION = 'CHAT_ADD_QUESTION';
export const CHAT_ADD_MODERATED_MESSAGE = 'CHAT_ADD_MODERATED_MESSAGE';
export const GET_CHAT_HISTORY = 'GET_CHAT_HISTORY';
export const GET_CHAT_HISTORY_SUCCESS = 'GET_CHAT_HISTORY_SUCCESS';
export const GET_CHAT_HISTORY_ERROR = 'GET_CHAT_HISTORY_ERROR';
export const GET_CHAT_WEBINAR = 'GET_CHAT_WEBINAR';
export const GET_CHAT_WEBINAR_SUCCESS = 'GET_CHAT_WEBINAR_SUCCESS';
export const GET_CHAT_WEBINAR_ERROR = 'GET_CHAT_WEBINAR_ERROR';

export const CHAT_ADD_USER_TO_ONLINE_LIST = 'CHAT_ADD_USER_TO_ONLINE_LIST';
export const CHAT_REMOVE_USER_TO_ONLINE_LIST =
  'CHAT_REMOVE_USER_TO_ONLINE_LIST';

export const CHAT_REMOVE_MODERATED_MESSAGE = 'CHAT_REMOVE_MODERATED_MESSAGE';

export const GET_QUESTIONS_HISTORY = 'GET_QUESTIONS_HISTORY';
export const GET_QUESTIONS_HISTORY_SUCCESS =
  'GET_QUESTIONS_HISTORY_BY_WEBINARID_SUCCESS';
export const GET_QUESTIONS_HISTORY_ERROR =
  'GET_QUESTIONS_HISTORY_BY_WEBINARID_ERROR';

export const IS_SUBSCRIPTION_AUTHORIZED = 'IS_SUBSCRIPTION_AUTHORIZED';
export const IS_SUBSCRIPTION_AUTHORIZED_SUCCESS =
  'IS_SUBSCRIPTION_AUTHORIZED_SUCCESS';
export const IS_SUBSCRIPTION_AUTHORIZED_ERROR =
  'IS_SUBSCRIPTION_AUTHORIZED_ERROR';

export const PATCH_ACCOUNT_ROLE = 'PATCH_ACCOUNT_ROLE';
export const PATCH_ACCOUNT_ROLE_SUCCESS = 'PATCH_ACCOUNT_ROLE_SUCCESS';
export const PATCH_ACCOUNT_ROLE_ERROR = 'PATCH_ACCOUNT_ROLE_ERROR';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';
export const SEND_SURVEY_ANSWER = 'SEND_SURVEY_ANSWER';

export const SEND_SURVEY_ANSWER_SUCCESS = 'SEND_SURVEY_ANSWER_SUCCESS';
export const SEND_SURVEY_ANSWER_ERROR = 'SEND_SURVEY_ANSWER_ERROR';

/* WATCH APP */
export const GET_SUBSCRIBER_WATCH_INFO = 'GET_SUBSCRIBER_WATCH_INFO';
export const GET_SUBSCRIBER_WATCH_INFO_SUCCESS =
  'GET_SUBSCRIBER_WATCH_INFO_SUCCESS';
export const GET_SUBSCRIBER_WATCH_INFO_ERROR =
  'GET_SUBSCRIBER_WATCH_INFO_ERROR';

export const GET_ADMIN_WATCH_INFO = 'GET_ADMIN_WATCH_INFO';
export const GET_ADMIN_WATCH_INFO_SUCCESS = 'GET_ADMIN_WATCH_INFO_SUCCESS';
export const GET_ADMIN_WATCH_INFO_ERROR = 'GET_ADMIN_WATCH_INFO_ERROR';

export const UPDATE_WATCHED_TIME = 'UPDATE_WATCHED_TIME';
export const UPDATE_WATCHED_TIME_SUCCESS = 'UPDATE_WATCHED_TIME_SUCCESS';
export const UPDATE_WATCHED_TIME_ERROR = 'UPDATE_WATCHED_TIME_ERROR';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';
export const GET_SURVEYS = 'GET_SURVEYS';
export const GET_SURVEYS_SUCCESS = 'GET_SURVEYS_SUCCESS';
export const GET_SURVEYS_ERROR = 'GET_SURVEYS_ERROR';

/* Subscriptions  */

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_ERROR = 'CREATE_SUBSCRIPTION_ERROR';
export const GET_SUBSCRIPTION_TOKEN = 'GET_SUBSCRIPTION_TOKEN';
export const GET_SUBSCRIPTION_TOKEN_SUCCESS = 'GET_SUBSCRIPTION_TOKEN_SUCCESS';
export const GET_SUBSCRIPTION_TOKEN_ERROR = 'GET_SUBSCRIPTION_TOKEN_ERROR';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_ERROR = 'GET_SUBSCRIPTION_ERROR';
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_ERROR = 'DELETE_SUBSCRIPTION_ERROR';
export const POST_APPROVE_PAYMENT = 'POST_APPROVE_PAYMENT';
export const POST_APPROVE_PAYMENT_SUCCESS = 'POST_APPROVE_PAYMENT_SUCCESS';
export const POST_APPROVE_PAYMENT_ERROR = 'POST_APPROVE_PAYMENT_ERROR';
export const PATCH_SUBSCRIPTION = 'PATCH_SUBSCRIPTION';
export const PATCH_SUBSCRIPTION_SUCCESS = 'PATCH_SUBSCRIPTION_SUCCESS';
export const PATCH_SUBSCRIPTION_ERROR = 'PATCH_SUBSCRIPTION_ERROR';

export const PAY_SUBSCRIPTION = 'PAY_SUBSCRIPTION';
export const PAY_SUBSCRIPTION_SUCCESS = 'PAY_SUBSCRIPTION_SUCCESS';
export const PAY_SUBSCRIPTION_ERROR = 'PAY_SUBSCRIPTION_ERROR';

export const GET_PUBLIC_KEY = 'GET_PUBLIC_KEY';
export const GET_PUBLIC_KEY_SUCCESS = 'GET_PUBLIC_KEY_SUCCESS';
export const GET_PUBLIC_KEY_ERROR = 'GET_PUBLIC_KEY_ERROR';

export const IMPORT_SUBSCRIPTIONS = 'IMPORT_SUBSCRIPTIONS';
export const IMPORT_SUBSCRIPTIONS_SUCCESS = 'IMPORT_SUBSCRIPTIONS_SUCCESS';
export const IMPORT_SUBSCRIPTIONS_ERROR = 'IMPORT_SUBSCRIPTIONS_ERROR';

/** Coupons */
export const CREATE_COUPON = 'CREATE_COUPON';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_ERROR = 'CREATE_COUPON_ERROR';
export const GET_COUPONS = 'GET_COUPONS';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_ERROR = 'GET_COUPONS_ERROR';
export const DELETE_COUPON = 'DELETE_COUPON';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_ERROR = 'DELETE_COUPON_ERROR';
export const INVALIDATE_COUPON = 'INVALIDATE_COUPON';
export const INVALIDATE_COUPON_SUCCESS = 'INVALIDATE_COUPON_SUCCESS';
export const INVALIDATE_COUPON_ERROR = 'INVALIDATE_COUPON_ERROR';

/** Notification Payment * */
export const UPDATE_PAYPAL_PAYMENT_NOTIFICATION =
  'UPDATE_PAYPAL_PAYMENT_NOTIFICATION';
export const UPDATE_PAYPAL_PAYMENT_NOTIFICATION_SUCCESS =
  'UPDATE_PAYPAL_PAYMENT_NOTIFICATION_SUCCESS';
export const UPDATE_PAYPAL_PAYMENT_NOTIFICATION_ERROR =
  'UPDATE_PAYPAL_PAYMENT_NOTIFICATION_ERROR';
export const POST_PAGARME_PROCESS_PAYMENT = 'POST_PAGARME_PROCESS_PAYMENT';
export const POST_PAGARME_PROCESS_PAYMENT_SUCCESS =
  'POST_PAGARME_PROCESS_PAYMENT_SUCCESS';
export const POST_PAGARME_PROCESS_PAYMENT_ERROR =
  'POST_PAGARME_PROCESS_PAYMENT_ERROR';
export const POST_PAGARME_RELEASE_PAYMENT = 'POST_PAGARME_RELEASE_PAYMENT';
export const POST_PAGARME_RELEASE_PAYMENT_SUCCESS =
  'POST_PAGARME_RELEASE_PAYMENT_SUCCESS';
export const POST_PAGARME_RELEASE_PAYMENT_ERROR =
  'POST_PAGARME_RELEASE_PAYMENT_ERROR';

/** Notification Paypal * */
export const POST_PAYPAL_NOTIFICATION = 'POST_PAYPAL_NOTIFICATION';
export const POST_PAYPAL_NOTIFICATION_SUCCESS =
  'POST_PAYPAL_NOTIFICATION_SUCCESS';
export const POST_PAYPAL_NOTIFICATION_ERROR = 'POST_PAYPAL_NOTIFICATION_ERROR';
/** Raffle */
export const GET_RAFFLE_RESULT = 'GET_RAFFLE_RESULT';
export const GET_RAFFLE_RESULT_SUCCESS = 'GET_RAFFLE_RESULT_SUCCESS';
export const GET_RAFFLE_RESULT_ERROR = 'GET_RAFFLE_RESULT_ERROR';
export const CREATE_RAFFLE = 'CREATE_RAFFLE';
export const CREATE_RAFFLE_SUCCESS = 'CREATE_RAFFLE_SUCCESS';
export const CREATE_RAFFLE_ERROR = 'CREATE_RAFFLE_ERROR';

/** SPECTATORS */
export const GET_SPECTATORS = 'GET_SPECTATORS';
export const GET_SPECTATORS_SUCCESS = 'GET_SPECTATORS_SUCCESS';
export const GET_SPECTATORS_ERROR = 'GET_SPECTATORS_ERROR';

/** GIFTS */
export const SEND_VIRTUAL_GIFT = 'SEND_VIRTUAL_GIFT';
export const SEND_VIRTUAL_GIFT_SUCCESS = 'SEND_VIRTUAL_GIFT_SUCCESS';
export const SEND_VIRTUAL_GIFT_ERROR = 'SEND_VIRTUAL_GIFT_ERROR';

export * from './menu/actions';
export * from './settings/actions';
export * from './webinar/actions';
export * from './watch/actions';
export * from './subscription/actions';
export * from './surveyList/actions';
export * from './raffle/actions';

// new Store
export * from './account/actions';
export * from './chatMessage/actions';
export * from './coupon/actions';
export * from './gift/actions';
export * from './metrics/actions';
export * from './pagarme/actions';
export * from './paypal/actions';
export * from './presenter/actions';
export * from './profile/actions';
export * from './survey/actions';
