import axios from 'axios';
import { NotificationManager } from '../components/common/react-notifications';
import { getAccessToken, setAccessToken, setCurrentUser } from './Utils';

// import { logoutUser } from '../redux/actions';

const codes = [413, 401, 409, 400, 403, 404, 500];

const validateStatus = (status) =>
  (status >= 200 && status < 300) || codes.includes(status);

const setToken = (config) => {
  const accessToken = getAccessToken();
  const headers = `Bearer ${accessToken}`;
  config.headers.Authorization = headers;
  return config;
};

// function setInsufficientScope() {
//   return { type: INSUFFICIENT_SCOPE, status: true };
// }

// const transformResponse = axios.defaults.transformResponse.concat((data) => {
//   if (data.statusCode && data.statusCode === HTTP_CODES.INSUFFICIENT_SCOPE) {
//     store.dispatch(setInsufficientScope());
//   }

//   return data;
// });

const backendClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  validateStatus,
  withCredentials: false,
});

backendClient.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      setAccessToken();
      setCurrentUser();
      if (response.data.message !== 'Login ou Senha incorretos.') {
        NotificationManager.warning(
          `Sua sessão expirou faça login novamente `,
          'Erro'
        );
        setTimeout(() => {
          window.location = `${process.env.REACT_APP_FRONTEND_URL}/user/login`;
        }, 5000);
      }
    }
    return response;
  },
  (error) => {
    console.error(error.message);
    throw new Error(error.message);
  }
);

backendClient.interceptors.request.use(setToken);

const downloadFile = (url, filename) => {
  axios({
    url,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const blobUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = blobUrl;
      link.setAttribute('download', filename);
      link.click();
    })
    .catch(console.error);
};

export { backendClient, downloadFile };
