import {
  CREATE_COUPON,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_ERROR,
  GET_COUPONS,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_ERROR,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_ERROR,
  INVALIDATE_COUPON,
  INVALIDATE_COUPON_SUCCESS,
  INVALIDATE_COUPON_ERROR,
} from '../actions';

const INIT_STATE = {
  items: [],
  error: '',
  loadingGetCoupons: false,
  loadingRequest: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_COUPON:
      return { ...state, loadingRequest: true, error: '' };
    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        loadingRequest: false,
        error: '',
      };
    case CREATE_COUPON_ERROR:
      return {
        ...state,
        loadingRequest: false,
        error: action.payload.message,
      };

    case DELETE_COUPON:
      return { ...state, loadingRequest: true, error: '' };
    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        loadingRequest: false,
        error: '',
      };
    case DELETE_COUPON_ERROR:
      return {
        ...state,
        loadingRequest: false,
        error: action.payload.message,
      };

    case INVALIDATE_COUPON:
      return { ...state, loadingRequest: true, error: '' };
    case INVALIDATE_COUPON_SUCCESS:
      return {
        ...state,
        loadingRequest: false,
        error: '',
      };
    case INVALIDATE_COUPON_ERROR:
      return {
        ...state,
        loadingRequest: false,
        error: action.payload.message,
      };
    case GET_COUPONS:
      return { ...state, loadingGetCoupons: true, error: '' };
    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        loadingGetCoupons: false,
        error: '',
        items: action.payload,
      };
    case GET_COUPONS_ERROR:
      return {
        ...state,
        loadingGetCoupons: false,
        error: action.payload.message,
      };

    default:
      return { ...state };
  }
};
