import {
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_ERROR,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_ERROR,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_ERROR,
  POST_APPROVE_PAYMENT,
  POST_APPROVE_PAYMENT_SUCCESS,
  POST_APPROVE_PAYMENT_ERROR,
  IS_SUBSCRIPTION_AUTHORIZED_ERROR,
  IS_SUBSCRIPTION_AUTHORIZED_SUCCESS,
  PAY_SUBSCRIPTION,
  PAY_SUBSCRIPTION_SUCCESS,
  PAY_SUBSCRIPTION_ERROR,
  GET_PUBLIC_KEY,
  GET_PUBLIC_KEY_SUCCESS,
  GET_PUBLIC_KEY_ERROR,
  GET_SUBSCRIPTION_TOKEN,
  GET_SUBSCRIPTION_TOKEN_SUCCESS,
  GET_SUBSCRIPTION_TOKEN_ERROR,
  PATCH_ACCOUNT_ROLE,
  PATCH_ACCOUNT_ROLE_SUCCESS,
  PATCH_ACCOUNT_ROLE_ERROR,
  GET_CERTIFICATE,
  GET_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_ERROR,
  POST_EMAIL_NOTIFICATION,
  POST_EMAIL_NOTIFICATION_SUCCESS,
  POST_EMAIL_NOTIFICATION_ERROR,
} from '../actions';

const INIT_STATE = {
  subscriptionId: '',
  loadingSubscriptionToken: false,
  error: '',
  role: '',
  invoiceUrl: null,
  pagarmePublicKey: null,
  loadingPagarmePublicKey: false,
  loadingModerator: false,
  hasSubscription: false,
  loadingGetSubscription: false,
  loadingDeleteSubscription: false,
  loadingPaySubscription: false,
  loadingCreateSubscription: false,
  loadingCertificate: false,
  loadingPostEmailNotification: false,
  finishedPayment: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_TOKEN:
      return { ...state, loadingSubscriptionToken: true, error: '' };
    case GET_SUBSCRIPTION_TOKEN_SUCCESS:
      return {
        ...state,
        loadingSubscriptionToken: false,
        error: '',
        subscriptionId: action.payload,
      };
    case GET_SUBSCRIPTION_TOKEN_ERROR:
      return {
        ...state,
        loadingSubscriptionToken: false,
        error: action.payload.message,
      };

    case CREATE_SUBSCRIPTION:
      return { ...state, loadingCreateSubscription: true, error: '' };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loadingCreateSubscription: false,
        error: '',
        subscriptionId: action.payload,
      };
    case CREATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loadingCreateSubscription: false,
        error: action.payload.message,
      };
    case GET_SUBSCRIPTION:
      return { ...state, loadingGetSubscription: true, error: '' };
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loadingGetSubscription: false,
        error: '',
        ...action.payload,
      };
    case GET_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loadingGetSubscription: false,
        error: action.payload.message,
      };
    case DELETE_SUBSCRIPTION:
      return { ...state, loadingDeleteSubscription: true, error: '' };
    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loadingDeleteSubscription: false,
        error: '',
      };
    case DELETE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loadingDeleteSubscription: false,
        error: action.payload.message,
      };
    case POST_APPROVE_PAYMENT:
      return { ...state, loadingDeleteSubscription: true, error: '' };
    case POST_APPROVE_PAYMENT_SUCCESS:
      return {
        ...state,
        loadingDeleteSubscription: false,
        error: '',
      };
    case POST_APPROVE_PAYMENT_ERROR:
      return {
        ...state,
        loadingDeleteSubscription: false,
        error: action.payload.message,
      };

    case PAY_SUBSCRIPTION:
      return {
        ...state,
        invoiceUrl: null,
        loadingPaySubscription: true,
        error: '',
      };
    case PAY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        finishedPayment: true,
        invoiceUrl: action.payload,
        loadingPaySubscription: false,
        error: '',
      };
    case PAY_SUBSCRIPTION_ERROR:
      return {
        ...state,
        invoiceUrl: null,
        finishedPayment: true,
        loadingPaySubscription: false,
        error: action.payload.message,
      };

    case GET_PUBLIC_KEY:
      return {
        ...state,
        loadingPagarmePublicKey: true,
        pagarmePublicKey: null,
        error: '',
      };
    case GET_PUBLIC_KEY_SUCCESS:
      return {
        ...state,
        loadingPagarmePublicKey: false,
        pagarmePublicKey: action.payload,
        error: '',
      };
    case GET_PUBLIC_KEY_ERROR:
      return {
        ...state,
        pagarmePublicKey: null,
        loadingPagarmePublicKey: false,
        error: action.payload.message,
      };
    case PATCH_ACCOUNT_ROLE:
      return {
        ...state,
        loadingModerator: true,
        error: '',
      };
    case PATCH_ACCOUNT_ROLE_SUCCESS:
      return {
        ...state,
        loadingModerator: false,
        error: '',
      };
    case PATCH_ACCOUNT_ROLE_ERROR:
      return {
        ...state,
        loadingModerator: false,
        error: action.payload.message,
      };
    case GET_CERTIFICATE:
      return {
        ...state,
        loadingCertificate: true,
        error: '',
      };
    case GET_CERTIFICATE_SUCCESS:
      return {
        ...state,
        loadingCertificate: false,
        error: '',
      };
    case GET_CERTIFICATE_ERROR:
      return {
        ...state,
        loadingCertificate: false,
      };
    case POST_EMAIL_NOTIFICATION:
      return {
        ...state,
        loadingPostEmailNotification: true,
        error: '',
      };
    case POST_EMAIL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingPostEmailNotification: false,
        error: '',
      };
    case POST_EMAIL_NOTIFICATION_ERROR:
      return {
        ...state,
        loadingPostEmailNotification: false,
        error: action.payload.message,
      };

    case IS_SUBSCRIPTION_AUTHORIZED_ERROR:
      return { ...state, error: action.payload };
    case IS_SUBSCRIPTION_AUTHORIZED_SUCCESS:
      return { ...state, error: '' };
    default:
      return { ...state };
  }
};
