import {
  SEND_SURVEY_ANSWER,
  SEND_SURVEY_ANSWER_SUCCESS,
  SEND_SURVEY_ANSWER_ERROR,
  GET_SURVEYS,
  GET_SURVEYS_SUCCESS,
  GET_SURVEYS_ERROR,
  CHAT_ADD_SURVEY,
  SURVEY_DELETE_QUESTION,
  SURVEY_SAVE,
} from '../actions';

const INIT_STATE = {
  survey: { questions: [] },
  loading: false,
  loadingSendSurvey: false,
  loadingGetSurveys: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_SURVEY_ANSWER:
      return {
        ...state,
        loadingSendSurvey: true,
      };

    case SEND_SURVEY_ANSWER_SUCCESS:
      return {
        ...state,
        survey: {
          questions: state.survey.questions.filter(
            (q) => q.surveyId !== action.payload.surveyId
          ),
        },
        loadingSendSurvey: false,
      };

    case SEND_SURVEY_ANSWER_ERROR:
      return {
        ...state,
        loadingSendSurvey: false,
        error: action.payload,
      };
    case GET_SURVEYS:
      return {
        ...state,
        loadingGetSurveys: true,
      };

    case GET_SURVEYS_SUCCESS:
      return {
        ...state,
        survey: {
          questions: action.payload,
        },
        loadingGetSurveys: false,
      };

    case GET_SURVEYS_ERROR:
      return {
        ...state,
        loadingGetSurveys: false,
        error: action.payload,
      };

    case CHAT_ADD_SURVEY:
      return {
        ...state,
        survey: {
          questions: [...state.survey.questions, action.payload.survey],
        },
      };

    case SURVEY_DELETE_QUESTION:
      return { ...state, loading: false };

    case SURVEY_SAVE:
      return { ...state, loading: true, survey: action.payload };

    default:
      return { ...state };
  }
};
