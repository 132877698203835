import {
  SEND_SURVEY_ANSWER_ERROR,
  SEND_SURVEY_ANSWER_SUCCESS,
  SEND_SURVEY_ANSWER,
  GET_SURVEYS,
  GET_SURVEYS_SUCCESS,
  GET_SURVEYS_ERROR,
  CHAT_ADD_SURVEY,
  SURVEY_DELETE_QUESTION,
  SURVEY_SAVE,
  SURVEY_GET_DETAILS_ERROR,
} from '../actions';

export const deleteSurveyQuestion = (id, survey) => ({
  type: SURVEY_DELETE_QUESTION,
  payload: { id, survey },
});

export const getSurveyDetailError = (error) => ({
  type: SURVEY_GET_DETAILS_ERROR,
  payload: error,
});

export const saveSurvey = (survey) => ({
  type: SURVEY_SAVE,
  payload: survey,
});

export const sendSurveyAnswer = (surveyId, index) => ({
  type: SEND_SURVEY_ANSWER,
  payload: { surveyId, index },
});

export const sendSurveyAnswerSuccess = (surveyId) => ({
  type: SEND_SURVEY_ANSWER_SUCCESS,
  payload: { surveyId },
});

export const sendSurveyAnswerError = (surveyId) => ({
  type: SEND_SURVEY_ANSWER_ERROR,
  payload: { surveyId },
});

export const getSurveys = (webinarId) => ({
  type: GET_SURVEYS,
  payload: { webinarId },
});

export const getSurveysSuccess = (surveys) => ({
  type: GET_SURVEYS_SUCCESS,
  payload: surveys,
});

export const getSurveysError = (error) => ({
  type: GET_SURVEYS_ERROR,
  payload: error,
});

export const addSurvey = (survey) => ({
  type: CHAT_ADD_SURVEY,
  payload: { survey },
});
