import {
  WEBINAR_PRESENTER_ADD_ITEM,
  WEBINAR_PRESENTER_ADD_ITEM_SUCCESS,
  WEBINAR_PRESENTER_ADD_ITEM_ERROR,
  WEBINAR_GET_PRESENTERS,
  WEBINAR_GET_PRESENTERS_SUCCESS,
  WEBINAR_GET_PRESENTERS_ERROR,
  WEBINAR_DELETE_PRESENTER,
  WEBINAR_DELETE_PRESENTER_SUCCESS,
  WEBINAR_DELETE_PRESENTER_ERROR,
} from '../actions';

const INIT_STATE = {
  presenters: [],
  metrics: {
    totalWebinars: 0,
    totalSubscribers: 0,
    totalAccesses: 0,
    totalBalance: 0,
  },
  error: '',
  filter: null,
  orderColumn: null,
  addedNewPresenter: false,
  loadingPresenters: false,
  deletedPresenter: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WEBINAR_PRESENTER_ADD_ITEM:
      return {
        ...state,
        addedNewPresenter: false,
      };

    case WEBINAR_PRESENTER_ADD_ITEM_SUCCESS:
      return {
        ...state,
        addedNewPresenter: true,
      };

    case WEBINAR_PRESENTER_ADD_ITEM_ERROR:
      return { ...state, addedNewPresenter: false, error: action.payload };

    case WEBINAR_GET_PRESENTERS:
      return { ...state, loadingPresenters: true };

    case WEBINAR_GET_PRESENTERS_SUCCESS:
      return {
        ...state,
        loadingPresenters: false,
        presenters: action.payload,
      };

    case WEBINAR_GET_PRESENTERS_ERROR:
      return { ...state, loadingPresenters: false, error: action.payload };

    case WEBINAR_DELETE_PRESENTER:
      return { ...state, deletedPresenter: false };

    case WEBINAR_DELETE_PRESENTER_SUCCESS:
      return {
        ...state,
        deletedPresenter: true,
        presenters: state.presenters.filter(
          (presenter) => presenter.presenterId !== action.payload.presenterId
        ),
      };

    case WEBINAR_DELETE_PRESENTER_ERROR:
      return { ...state, deletedPresenter: false, error: action.payload };

    default:
      return { ...state };
  }
};
