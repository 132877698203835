import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SEND_VIRTUAL_GIFT } from '../actions';

import { sendVirtualGiftSuccess, sendVirtualGiftError } from './actions';

import { backendClient } from '../../helpers/http';
import { NotificationManager } from '../../components/common/react-notifications';

const sendVirtualGiftRequest = (payload) => {
  const data = new FormData();
  data.append('picture', payload);
  return backendClient
    .post(`/gift`, data)
    .then((user) => user)
    .catch((error) => error);
};

function* sendVirtualGift({ payload }) {
  try {
    const response = yield call(sendVirtualGiftRequest, payload);
    if (response.status === 200) {
      yield put(sendVirtualGiftSuccess(response.data));
      NotificationManager.success(
        'Foto enviada com sucesso',
        'Sucesso!',
        3000,
        null,
        null,
        ''
      );
    } else {
      yield put(sendVirtualGiftError(response.data.message));
      NotificationManager.warning(
        response.data.message,
        'Erro!',
        3000,
        null,
        null,
        ''
      );
    }
  } catch (error) {
    yield put(sendVirtualGiftError(error));
  }
}
export function* watchSendVirtualGift() {
  yield takeEvery(SEND_VIRTUAL_GIFT, sendVirtualGift);
}

export default function* rootSaga() {
  yield all([fork(watchSendVirtualGift)]);
}
