import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  POST_PAGARME_PROCESS_PAYMENT,
  UPDATE_PAYPAL_PAYMENT_NOTIFICATION,
} from '../actions';
import {
  updateSubscriptionPaymentStatusSuccess,
  updateSubscriptionPaymentStatusError,
  postPagarmeProcessPaymentSuccess,
  postPagarmeProcessPaymentError,
  postPagarmeReleasePaymentSuccess,
  postPagarmeReleasePaymentError,
} from './actions';
import { backendClient } from '../../helpers/http';
import { NotificationManager } from '../../components/common/react-notifications';

const updateSubscriptionPaymentStatusRequest = ({
  subscriptionId,
  paymentGatewayId,
}) =>
  backendClient
    .post(`/pagarme/notification`, { subscriptionId, paymentGatewayId })
    .then((user) => user)
    .catch((error) => error);

function* updateSubscriptionPaymentStatus({ payload }) {
  const { callback, data } = payload;
  try {
    const response = yield call(updateSubscriptionPaymentStatusRequest, data);
    if (response.status === 200) {
      yield put(
        updateSubscriptionPaymentStatusSuccess(response.data.invoiceUrl)
      );
      NotificationManager.success(
        'Pagamento efetuado com sucesso!',
        'Sucesso!'
      );
      if (callback.success) {
        callback.success();
      }
    } else {
      yield put(updateSubscriptionPaymentStatusError(response.data.message));
      NotificationManager.warning(response.data.message, 'Erro de Pagamento');
      if (callback.fail) {
        callback.fail();
      }
    }
  } catch (error) {
    NotificationManager.warning('Erro no pagamento!', 'Erro');
    yield put(updateSubscriptionPaymentStatusError(error));
  }
}
export function* watchPaySubscription() {
  yield takeEvery(
    UPDATE_PAYPAL_PAYMENT_NOTIFICATION,
    updateSubscriptionPaymentStatus
  );
}

const postPagarmeProcessPaymentRequest = ({
  subscriptionId,
  paymentMethod,
  cardHash,
  installments,
}) =>
  backendClient
    .post(`/pagarme/process-payment`, {
      subscriptionId,
      paymentMethod,
      cardHash,
      installments,
    })
    .then((user) => user)
    .catch((error) => error);

function* postPagarmeProcessPayment({ payload }) {
  const { callback, data } = payload;
  try {
    const response = yield call(postPagarmeProcessPaymentRequest, data);
    if (response.status === 200) {
      yield put(postPagarmeProcessPaymentSuccess(response.data.invoiceUrl));
      NotificationManager.success(
        'Pagamento efetuado com sucesso!',
        'Sucesso!'
      );
      if (callback.success) {
        callback.success();
      }
    } else {
      yield put(postPagarmeProcessPaymentError(response.data.message));
      NotificationManager.warning(response.data.message, 'Erro de Pagamento');
      if (callback.fail) {
        callback.fail();
      }
    }
  } catch (error) {
    NotificationManager.warning('Erro no pagamento!', 'Erro');
    yield put(postPagarmeProcessPaymentError(error));
  }
}
export function* watchPostPagarmeProcessPayment() {
  yield takeEvery(POST_PAGARME_PROCESS_PAYMENT, postPagarmeProcessPayment);
}

const postPagarmeReleasePaymentRequest = ({ email }) =>
  backendClient
    .post(`/pagarme/release-payment`, {
      email,
    })
    .then((user) => user)
    .catch((error) => error);

function* postPagarmeReleasePayment({ payload }) {
  const { callback, data } = payload;
  try {
    const response = yield call(postPagarmeReleasePaymentRequest, data);
    if (response.status === 200) {
      yield put(postPagarmeReleasePaymentSuccess(response.data.invoiceUrl));
      NotificationManager.success(
        'Pagamento efetuado com sucesso!',
        'Sucesso!'
      );
      if (callback.success) {
        callback.success();
      }
    } else {
      yield put(postPagarmeReleasePaymentError(response.data.message));
      NotificationManager.warning(response.data.message, 'Erro de Pagamento');
      if (callback.fail) {
        callback.fail();
      }
    }
  } catch (error) {
    NotificationManager.warning('Erro no pagamento!', 'Erro');
    yield put(postPagarmeReleasePaymentError(error));
  }
}
export function* watchPostPagarmeReleasePayment() {
  yield takeEvery(POST_PAGARME_PROCESS_PAYMENT, postPagarmeReleasePayment);
}

export default function* rootSaga() {
  yield all([fork(watchPaySubscription), fork(watchPostPagarmeProcessPayment)]);
}
