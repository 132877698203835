import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  GET_SUBSCRIBER_WATCH_INFO,
  GET_ADMIN_WATCH_INFO,
  getQuestionsHistorySuccess,
  getSurveysSuccess,
  getChatHistorySuccess,
} from '../actions';
import {
  getAdminWatchInfoSuccess,
  getAdminWatchInfoError,
  getSubscriptionWatchInfoSuccess,
  getSubscriptionWatchInfoError,
} from './actions';
import { backendClient } from '../../helpers/http';

import { getWebinarItemSuccess } from '../webinar/actions';
import { getSubscriptionSuccess } from '../subscription/actions';
import { NotificationManager } from '../../components/common/react-notifications';
import { getRaffleResultSuccess } from '../raffle/actions';

const getSubscriptionWatchInfoRequest = ({ subscriptionId }) =>
  backendClient.get(`/watch/${subscriptionId}`);

function* getSubscriptionWatchInfo({ payload }) {
  try {
    const response = yield call(getSubscriptionWatchInfoRequest, payload);
    if (response.status === 200) {
      const {
        chatRoomId,
        firstName,
        subscriptionId,
        paidSubscription,
        messages,
        options,
        questions,
        streamingUris,
        streamingService,
        watchToken,
        webinarId,
        defaultStreamingUriKey,
        role,
      } = response.data;

      yield put(getQuestionsHistorySuccess(questions));
      yield put(getChatHistorySuccess(messages));
      yield put(
        getSubscriptionSuccess({
          firstName,
          subscriptionId,
          watchToken,
          paidSubscription,
          role,
        })
      );
      yield put(
        getWebinarItemSuccess({
          chatRoomId,
          streamingUris,
          streamingService,
          defaultStreamingUriKey,
          webinarId,
          options,
        })
      );
      yield put(getSubscriptionWatchInfoSuccess());
    } else {
      yield put(getSubscriptionWatchInfoError(response.data.message));
      NotificationManager.warning(response.data.message, 'Erro');
      if (payload.callback) {
        payload.callback.fail(response.data.message);
      }
    }
  } catch (error) {
    yield put(getSubscriptionWatchInfoError(error));
    NotificationManager.warning(error.message, 'Erro');
  }
}

const getAdminWatchInfoRequest = ({ webinarId }) =>
  backendClient.get(`watch/${webinarId}/admin`);

function* getAdminWatchInfo({ payload }) {
  try {
    const response = yield call(getAdminWatchInfoRequest, payload);
    if (response.status === 200) {
      const {
        chatRoomId,
        messages,
        options,
        questions,
        streamingUris,
        streamingService,
        webinarId,
        surveys,
        raffles,
        defaultStreamingUriKey,
      } = response.data;

      yield put(getQuestionsHistorySuccess(questions));
      yield put(getChatHistorySuccess(messages));
      yield put(getSurveysSuccess(surveys));
      yield put(
        getWebinarItemSuccess({
          chatRoomId,
          streamingUris,
          streamingService,
          defaultStreamingUriKey,
          webinarId,
          options,
        })
      );
      yield put(getAdminWatchInfoSuccess());
      yield put(getRaffleResultSuccess(raffles));
    }
    if (response.status === 401) {
      yield put(
        getAdminWatchInfoError(
          'O link dessa live foi compartilhado e está sendo usado por outras pessoas. Se você não compartilhou esse link, entre em contato com o suporte: suporte@forlive.io'
        )
      );
    }
  } catch (error) {
    yield put(getAdminWatchInfoError(error.message));
  }
}

export function* watchGetAdminWatchInfo() {
  yield takeEvery(GET_ADMIN_WATCH_INFO, getAdminWatchInfo);
}

export function* watchGetSubscriberWatchInfo() {
  yield takeEvery(GET_SUBSCRIBER_WATCH_INFO, getSubscriptionWatchInfo);
}

export default function* rootSaga() {
  yield all([fork(watchGetSubscriberWatchInfo), fork(watchGetAdminWatchInfo)]);
}
