import { all } from 'redux-saga/effects';
import watchSagas from './watch/saga';
import webinarSagas from './webinar/saga';
import PaymentSagas from './payment/saga';
import raffleSagas from './raffle/saga';
import subscriptionSagas from './subscription/saga';
import couponSagas from './coupon/saga';
import surveyListSagas from './surveyList/saga';

import accountSagas from './account/saga';
import chatMessageSagas from './chatMessage/saga';
import metricsSagas from './metrics/saga';
import pagarmeSagas from './pagarme/saga';
import giftSagas from './gift/saga';
import paypalSagas from './paypal/saga';
import presenterSagas from './presenter/saga';
import profileSagas from './profile/saga';
import surveySagas from './survey/saga';

export default function* rootSaga(getState) {
  yield all([
    accountSagas(),
    chatMessageSagas(),
    metricsSagas(),
    pagarmeSagas(),
    paypalSagas(),
    presenterSagas(),
    profileSagas(),
    surveySagas(),

    giftSagas(),
    subscriptionSagas(),
    PaymentSagas(),
    watchSagas(),
    raffleSagas(),
    couponSagas(),
    webinarSagas(),
    surveyListSagas(),
  ]);
}
