import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { UPDATE_PAYPAL_PAYMENT_NOTIFICATION } from '../actions';
import {
  updateSubscriptionPaymentStatusSuccess,
  updateSubscriptionPaymentStatusError,
} from './actions';
import { backendClient } from '../../helpers/http';
import { NotificationManager } from '../../components/common/react-notifications';

const updateSubscriptionPaymentStatusRequest = ({
  subscriptionId,
  paymentGatewayId,
}) =>
  backendClient
    .post(`/paypal/notification`, { subscriptionId, paymentGatewayId })
    .then((user) => user)
    .catch((error) => error);

function* updateSubscriptionPaymentStatus({ payload }) {
  const { callback, data } = payload;
  try {
    const response = yield call(updateSubscriptionPaymentStatusRequest, data);
    if (response.status === 200) {
      yield put(
        updateSubscriptionPaymentStatusSuccess(response.data.invoiceUrl)
      );
      NotificationManager.success(
        'Pagamento efetuado com sucesso!',
        'Sucesso!'
      );
      if (callback.success) {
        callback.success();
      }
    } else {
      yield put(updateSubscriptionPaymentStatusError(response.data.message));
      NotificationManager.warning(response.data.message, 'Erro de Pagamento');
      if (callback.fail) {
        callback.fail();
      }
    }
  } catch (error) {
    NotificationManager.warning('Erro no pagamento!', 'Erro');
    yield put(updateSubscriptionPaymentStatusError(error));
  }
}
export function* watchPaySubscription() {
  yield takeEvery(
    UPDATE_PAYPAL_PAYMENT_NOTIFICATION,
    updateSubscriptionPaymentStatus
  );
}

export default function* rootSaga() {
  yield all([fork(watchPaySubscription)]);
}
