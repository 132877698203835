import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from '../actions';

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
});

export const getUserProfileSuccess = (data) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: data,
});

export const getUserProfileError = (message) => ({
  type: GET_USER_PROFILE_ERROR,
  payload: { message },
});

export const updateUser = (data) => ({
  type: UPDATE_USER,
  payload: data,
});

export const updateUserSuccess = () => ({
  type: UPDATE_USER_SUCCESS,
});

export const updateUserError = (message) => ({
  type: UPDATE_USER_ERROR,
  payload: { message },
});
