import {
  GET_RAFFLE_RESULT,
  GET_RAFFLE_RESULT_SUCCESS,
  GET_RAFFLE_RESULT_ERROR,
  CREATE_RAFFLE,
  CREATE_RAFFLE_SUCCESS,
  CREATE_RAFFLE_ERROR,
} from '../actions';

export const getRaffleResult = (webinarId) => ({
  type: GET_RAFFLE_RESULT,
  payload: { webinarId },
});

export const getRaffleResultSuccess = (winners) => ({
  type: GET_RAFFLE_RESULT_SUCCESS,
  payload: winners,
});

export const getRaffleResultError = (message) => ({
  type: GET_RAFFLE_RESULT_ERROR,
  payload: message,
});

export const createRaffle = (webinarId) => ({
  type: CREATE_RAFFLE,
  payload: { webinarId },
});

export const createRaffleSuccess = (winner) => ({
  type: CREATE_RAFFLE_SUCCESS,
  payload: winner,
});

export const createRaffleError = (message) => ({
  type: CREATE_RAFFLE_ERROR,
  payload: message,
});
