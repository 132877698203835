import {
  WEBINAR_GET_LIST,
  WEBINAR_GET_LIST_SUCCESS,
  WEBINAR_GET_LIST_ERROR,
  WEBINAR_GET_LIST_WITH_FILTER,
  WEBINAR_GET_LIST_WITH_ORDER,
  WEBINAR_GET_LIST_SEARCH,
  WEBINAR_ADD_ITEM,
  WEBINAR_ADD_ITEM_SUCCESS,
  WEBINAR_ADD_ITEM_ERROR,
  WEBINAR_SELECTED_ITEMS_CHANGE,
  WEBINAR_UPDATE,
  WEBINAR_UPDATE_SUCCESS,
  WEBINAR_UPDATE_ERROR,
  WEBINAR_GET_ITEM,
  WEBINAR_GET_ITEM_SUCCESS,
  WEBINAR_GET_ITEM_ERROR,
  GET_CHECKOUT_LINK,
  GET_CHECKOUT_LINK_SUCCESS,
  GET_CHECKOUT_LINK_ERROR,
  CANCEL_WEBINAR,
  CANCEL_WEBINAR_SUCCESS,
  CANCEL_WEBINAR_ERROR,
  UPDATE_LOGO,
  UPDATE_LOGO_SUCCESS,
  UPDATE_LOGO_ERROR,
  START_WEBINAR,
  START_WEBINAR_SUCCESS,
  START_WEBINAR_ERROR,
  WEBINAR_GET_ITEM_BY_SUBID,
  WEBINAR_GET_ITEM_BY_SUBID_SUCCESS,
  WEBINAR_GET_ITEM_BY_SUBID_ERROR,
  WEBINAR_GET_ITEM_DETAIL,
  WEBINAR_GET_SUBSCRIBERS,
  WEBINAR_GET_SUBSCRIBERS_SUCCESS,
  WEBINAR_GET_SUBSCRIBERS_ERROR,
  CLOSE_WEBINAR,
  CLOSE_WEBINAR_SUCCESS,
  CLOSE_WEBINAR_ERROR,
  WEBINAR_DOWNLOAD_REPORT,
  WEBINAR_DOWNLOAD_REPORT_SUCCESS,
  WEBINAR_DOWNLOAD_REPORT_ERROR,
  SUBSCRIBER_WEBINAR_GET_LIST,
  SUBSCRIBER_WEBINAR_GET_LIST_SUCCESS,
  SUBSCRIBER_WEBINAR_GET_LIST_ERROR,
  SET_CALLBACK_LINK,
  UPDATE_PROMO_IMG,
  UPDATE_PROMO_IMG_SUCCESS,
  UPDATE_PROMO_IMG_ERROR,
  UPDATE_CERTIFICATE,
  UPDATE_CERTIFICATE_SUCCESS,
  UPDATE_CERTIFICATE_ERROR
} from '../actions';

export const getWebinarList = () => ({
  type: WEBINAR_GET_LIST,
});

export const getWebinarListSuccess = (items) => ({
  type: WEBINAR_GET_LIST_SUCCESS,
  payload: items,
});

export const getWebinarListError = (error) => ({
  type: WEBINAR_GET_LIST_ERROR,
  payload: error,
});

export const getSubscribedWebinarList = () => ({
  type: SUBSCRIBER_WEBINAR_GET_LIST,
});

export const getSubscribedWebinarListSuccess = (items) => ({
  type: SUBSCRIBER_WEBINAR_GET_LIST_SUCCESS,
  payload: items,
});

export const getSubscribedWebinarListError = (error) => ({
  type: SUBSCRIBER_WEBINAR_GET_LIST_ERROR,
  payload: error,
});

export const getWebinarListWithFilter = (column, value) => ({
  type: WEBINAR_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getWebinarListWithOrder = (column) => ({
  type: WEBINAR_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getWebinarListSearch = (keyword) => ({
  type: WEBINAR_GET_LIST_SEARCH,
  payload: keyword,
});

export const addWebinarItem = (item) => ({
  type: WEBINAR_ADD_ITEM,
  payload: item,
});

export const addWebinarItemDetails = (item) => ({
  type: WEBINAR_GET_ITEM_DETAIL,
  payload: item,
  loadingGetItem: true,
});

export const addWebinarItemSuccess = (items) => ({
  type: WEBINAR_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addWebinarItemError = (error) => ({
  type: WEBINAR_ADD_ITEM_ERROR,
  payload: error,
});
export const updateWebinar = (item, callback) => ({
  type: WEBINAR_UPDATE,
  payload: { item, callback },
});
export const updateWebinarItemSuccess = (item) => ({
  type: WEBINAR_UPDATE_SUCCESS,
  payload: item,
});

export const updateWebinarItemError = (error) => ({
  type: WEBINAR_UPDATE_ERROR,
  payload: error,
});

export const getWebinar = (id) => ({
  type: WEBINAR_GET_ITEM,
  payload: id,
});

export const getWebinarItemSuccess = (items) => ({
  type: WEBINAR_GET_ITEM_SUCCESS,
  payload: items,
});

export const getWebinarItemError = (error) => ({
  type: WEBINAR_GET_ITEM_ERROR,
  payload: error,
});

export const getWebinarBySubscriberId = (id) => ({
  type: WEBINAR_GET_ITEM_BY_SUBID,
  payload: id,
});

export const getWebinarBySubscriberIdSuccess = (items) => ({
  type: WEBINAR_GET_ITEM_BY_SUBID_SUCCESS,
  payload: items,
});

export const getWebinarBySubscriberIdError = (error) => ({
  type: WEBINAR_GET_ITEM_BY_SUBID_ERROR,
  payload: error,
});

export const selectedWebinarItemsChange = (selectedItems) => ({
  type: WEBINAR_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

export const getCheckoutLink = (id) => ({
  type: GET_CHECKOUT_LINK,
  payload: { id },
});
export const getCheckoutLinkSuccess = (link) => ({
  type: GET_CHECKOUT_LINK_SUCCESS,
  payload: link,
});
export const getCheckoutLinkError = (error) => ({
  type: GET_CHECKOUT_LINK_ERROR,
  payload: error,
});

export const cancelWebinar = (id) => ({
  type: CANCEL_WEBINAR,
  payload: { id },
});
export const cancelWebinarSuccess = () => ({
  type: CANCEL_WEBINAR_SUCCESS,
});
export const cancelWebinarError = (error) => ({
  type: CANCEL_WEBINAR_ERROR,
  payload: error,
});

export const startWebinar = (id) => ({
  type: START_WEBINAR,
  payload: { id },
});
export const startWebinarSuccess = () => ({
  type: START_WEBINAR_SUCCESS,
});
export const startWebinarError = (error) => ({
  type: START_WEBINAR_ERROR,
  payload: error,
});

export const uploadLogo = (id, logo) => ({
  type: UPDATE_LOGO,
  payload: { id, logo },
});
export const uploadLogoSuccess = () => ({
  type: UPDATE_LOGO_SUCCESS,
});
export const uploadLogoError = (error) => ({
  type: UPDATE_LOGO_ERROR,
  payload: error,
});

export const uploadPromoImg = (id, promoImg) => ({
  type: UPDATE_PROMO_IMG,
  payload: { id, promoImg },
});
export const uploadPromoImgSuccess = () => ({
  type: UPDATE_PROMO_IMG_SUCCESS,
});
export const uploadPromoImgError = (error) => ({
  type: UPDATE_PROMO_IMG_ERROR,
  payload: error,
});

export const downloadWebinarReport = (id) => ({
  type: WEBINAR_DOWNLOAD_REPORT,
  payload: { id },
});
export const downloadWebinarReportSuccess = () => ({
  type: WEBINAR_DOWNLOAD_REPORT_SUCCESS,
});
export const downloadWebinarReportError = (error) => ({
  type: WEBINAR_DOWNLOAD_REPORT_ERROR,
  payload: error,
});

export const getWebinarSubscribers = (webinarId) => ({
  type: WEBINAR_GET_SUBSCRIBERS,
  payload: { webinarId },
});
export const getWebinarSubscribersSuccess = (subscriptions) => ({
  type: WEBINAR_GET_SUBSCRIBERS_SUCCESS,
  payload: subscriptions,
});
export const getWebinarSubscribersError = (message) => ({
  type: WEBINAR_GET_SUBSCRIBERS_ERROR,
  payload: message,
});

export const closeWebinar = (id) => ({
  type: CLOSE_WEBINAR,
  payload: { id },
});
export const closeWebinarSuccess = () => ({
  type: CLOSE_WEBINAR_SUCCESS,
});
export const closeWebinarError = (error) => ({
  type: CLOSE_WEBINAR_ERROR,
  payload: error,
});

export const setCallbackLink = (callbackLink) => ({
  type: SET_CALLBACK_LINK,
  payload: callbackLink,
});

export const updateCertificate = (id, logo) => ({
  type: UPDATE_CERTIFICATE,
  payload: { id, logo },
});
export const updateCertificateSuccess = () => ({
  type: UPDATE_CERTIFICATE_SUCCESS,
});
export const updateCertificateError = (error) => ({
  type: UPDATE_CERTIFICATE_ERROR,
  payload: error,
});
