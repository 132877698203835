import {
  GET_SUBSCRIBER_WATCH_INFO,
  GET_SUBSCRIBER_WATCH_INFO_ERROR,
  GET_SUBSCRIBER_WATCH_INFO_SUCCESS,
} from '../actions';

const INIT_STATE = {
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIBER_WATCH_INFO_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_SUBSCRIBER_WATCH_INFO_SUCCESS:
      return {
        ...state,
        error: '',
      };
    case GET_SUBSCRIBER_WATCH_INFO:
      return {
        ...state,
        error: '',
      };
    default:
      return { ...state };
  }
};
