import {
  WEBINAR_GET_METRICS,
  WEBINAR_GET_METRICS_SUCCESS,
  WEBINAR_GET_METRICS_ERROR,
  GET_METRICS_WEBINAR_ID,
  GET_METRICS_WEBINAR_ID_SUCCESS,
  GET_METRICS_WEBINAR_ID_ERROR,
} from '../actions';

const INIT_STATE = {
  metrics: [],
  metricsWebinarId: [],
  error: '',
  loadingGetMetrics: false,
  loadingGetMetricsWebinarId: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WEBINAR_GET_METRICS:
      return { ...state, loadingGetMetrics: true, error: '' };
    case WEBINAR_GET_METRICS_SUCCESS:
      return {
        ...state,
        loadingGetMetrics: false,
        error: '',
        metrics: action.payload,
      };
    case WEBINAR_GET_METRICS_ERROR:
      return {
        ...state,
        loadingGetMetrics: false,
        error: action.payload.message,
      };
    case GET_METRICS_WEBINAR_ID:
      return { ...state, loadingGetMetricsWebinarId: true, error: '' };
    case GET_METRICS_WEBINAR_ID_SUCCESS:
      return {
        ...state,
        loadingGetMetricsWebinarId: false,
        error: '',
        metricsWebinarId: action.payload,
      };
    case GET_METRICS_WEBINAR_ID_ERROR:
      return {
        ...state,
        loadingGetMetricsWebinarId: false,
        error: action.payload.message,
      };

    default:
      return { ...state };
  }
};
