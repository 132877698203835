import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { WEBINAR_GET_METRICS } from '../actions';

import {
  getWebinarMetricsSuccess,
  getWebinarMetricsError,
  getMetricsWebinarIdSuccess,
  getMetricsWebinarIdError,
} from './actions';

import { backendClient } from '../../helpers/http';
import { NotificationManager } from '../../components/common/react-notifications';

const getWebinarMetricsRequest = () => backendClient.get(`/metrics`);

function* getWebinarMetrics({ payload }) {
  try {
    const response = yield call(getWebinarMetricsRequest, payload);

    if (response.status === 200) {
      yield put(getWebinarMetricsSuccess(response.data));
    } else {
      const message = response.data
        ? response.data.message
        : 'Metricas não econtradas';
      NotificationManager.warning(message, 'Erro');
      yield put(getWebinarMetricsError(response.data.message));
    }
  } catch (error) {
    yield put(getWebinarMetricsError(error));
  }
}

export function* watchGetWebinarMetrics() {
  yield takeEvery(WEBINAR_GET_METRICS, getWebinarMetrics);
}

const getMetricsWebinarIdRequest = ({ webinarId }) =>
  backendClient.get(`/metrics/${webinarId}`);

function* getMetricsWebinarId({ payload }) {
  try {
    const response = yield call(getMetricsWebinarIdRequest, payload);

    if (response.status === 200) {
      yield put(getMetricsWebinarIdSuccess(response.data));
    } else {
      NotificationManager.warning(response.data.message, 'Erro');
      yield put(getMetricsWebinarIdError(response.data.message));
    }
  } catch (error) {
    yield put(getMetricsWebinarIdError(error));
  }
}

export function* watchgetMetricsWebinarId() {
  yield takeEvery(WEBINAR_GET_METRICS, getMetricsWebinarId);
}

export default function* rootSaga() {
  yield all([fork(watchGetWebinarMetrics), fork(watchgetMetricsWebinarId)]);
}
