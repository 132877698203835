import {
  POST_PAYPAL_NOTIFICATION,
  POST_PAYPAL_NOTIFICATION_SUCCESS,
  POST_PAYPAL_NOTIFICATION_ERROR,
} from '../actions';

export const postPaypalNotification = (data, callback) => ({
  type: POST_PAYPAL_NOTIFICATION,
  payload: { data, callback },
});

export const postPaypalNotificationSuccess = () => ({
  type: POST_PAYPAL_NOTIFICATION_SUCCESS,
});

export const postPaypalNotificationError = (message) => ({
  type: POST_PAYPAL_NOTIFICATION_ERROR,
  payload: { message },
});
