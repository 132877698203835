import {
  GET_CHAT_HISTORY,
  GET_CHAT_HISTORY_SUCCESS,
  GET_CHAT_HISTORY_ERROR,
  GET_QUESTIONS_HISTORY,
  GET_QUESTIONS_HISTORY_SUCCESS,
  GET_QUESTIONS_HISTORY_ERROR,
  CHAT_ADD_MESSAGE,
  SET_WSCONNECTION,
  CHAT_ADD_MODERATED_MESSAGE,
  CHAT_REMOVE_MODERATED_MESSAGE,
  CHAT_ADD_QUESTION,
  GET_SUBSCRIPTION_SUCCESS,
  CHAT_ADD_USER_TO_ONLINE_LIST,
  CHAT_REMOVE_USER_TO_ONLINE_LIST,
} from '../actions';

export const setWsConnection = (connection) => ({
  type: SET_WSCONNECTION,
  payload: connection,
});

export const addModeratedChatMessage = (item) => ({
  type: CHAT_ADD_MODERATED_MESSAGE,
  payload: item,
});

export const removeModeratedChatMessage = (index) => ({
  type: CHAT_REMOVE_MODERATED_MESSAGE,
  payload: { index },
});

export const addQuestion = (userName, questionContent) => ({
  type: CHAT_ADD_QUESTION,
  payload: { userName, questionContent },
});

export const resetSubscriberData = () => ({
  type: GET_SUBSCRIPTION_SUCCESS,
  payload: {},
});

export const addChatMessage = (item) => ({
  type: CHAT_ADD_MESSAGE,
  payload: item,
});

export const addUserToOnlineList = (name) => ({
  type: CHAT_ADD_USER_TO_ONLINE_LIST,
  payload: name,
});

export const removeUserToOnlineList = (name) => ({
  type: CHAT_REMOVE_USER_TO_ONLINE_LIST,
  payload: name,
});

export const getChatHistory = (chatRoomId) => ({
  type: GET_CHAT_HISTORY,
  payload: { chatRoomId },
});

export const getChatHistorySuccess = (history) => ({
  type: GET_CHAT_HISTORY_SUCCESS,
  payload: history,
});

export const getChatHistoryError = (message) => ({
  type: GET_CHAT_HISTORY_ERROR,
  payload: message,
});

export const getQuestionsHistory = (webinarId, subscriptionId) => ({
  type: GET_QUESTIONS_HISTORY,
  payload: { webinarId, subscriptionId },
});

export const getQuestionsHistorySuccess = (history) => ({
  type: GET_QUESTIONS_HISTORY_SUCCESS,
  payload: history,
});

export const getQuestionsHistoryError = (message) => ({
  type: GET_QUESTIONS_HISTORY_ERROR,
  payload: message,
});
