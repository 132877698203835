import {
  CREATE_COUPON,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_ERROR,
  GET_COUPONS,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_ERROR,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_ERROR,
  INVALIDATE_COUPON,
  INVALIDATE_COUPON_SUCCESS,
  INVALIDATE_COUPON_ERROR,
} from '../actions';

export const getCoupons = (webinarId) => ({
  type: GET_COUPONS,
  payload: { webinarId },
});
export const getCouponsSuccess = (coupons) => ({
  type: GET_COUPONS_SUCCESS,
  payload: coupons,
});
export const getCouponsError = (message) => ({
  type: GET_COUPONS_ERROR,
  payload: { message },
});

export const createCoupon = (webinarId, couponData) => ({
  type: CREATE_COUPON,
  payload: { webinarId, couponData },
});
export const createCouponSuccess = (id) => ({
  type: CREATE_COUPON_SUCCESS,
  payload: id,
});
export const createCouponError = (message) => ({
  type: CREATE_COUPON_ERROR,
  payload: { message },
});

export const deleteCoupon = (couponId) => ({
  type: DELETE_COUPON,
  payload: { couponId },
});
export const deleteCouponSuccess = () => ({
  type: DELETE_COUPON_SUCCESS,
});
export const deleteCouponError = (message) => ({
  type: DELETE_COUPON_ERROR,
  payload: { message },
});

export const invalidateCoupon = (couponId) => ({
  type: INVALIDATE_COUPON,
  payload: { couponId },
});
export const invalidateCouponSuccess = () => ({
  type: INVALIDATE_COUPON_SUCCESS,
});
export const invalidateCouponError = (message) => ({
  type: INVALIDATE_COUPON_ERROR,
  payload: { message },
});
