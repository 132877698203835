import {
  GET_SUBSCRIBER_WATCH_INFO,
  GET_SUBSCRIBER_WATCH_INFO_SUCCESS,
  GET_SUBSCRIBER_WATCH_INFO_ERROR,
  GET_ADMIN_WATCH_INFO,
  GET_ADMIN_WATCH_INFO_SUCCESS,
  GET_ADMIN_WATCH_INFO_ERROR,
} from '../actions';

export const getSubscriptionWatchInfo = (subscriptionId, callback) => ({
  type: GET_SUBSCRIBER_WATCH_INFO,
  payload: { subscriptionId, callback },
});

export const getSubscriptionWatchInfoSuccess = (history) => ({
  type: GET_SUBSCRIBER_WATCH_INFO_SUCCESS,
  payload: history,
});

export const getSubscriptionWatchInfoError = (message) => ({
  type: GET_SUBSCRIBER_WATCH_INFO_ERROR,
  payload: message,
});

export const getAdminWatchInfo = (webinarId) => ({
  type: GET_ADMIN_WATCH_INFO,
  payload: { webinarId },
});

export const getAdminWatchInfoSuccess = () => ({
  type: GET_ADMIN_WATCH_INFO_SUCCESS,
});

export const getAdminWatchInfoError = (message) => ({
  type: GET_ADMIN_WATCH_INFO_ERROR,
  payload: message,
});