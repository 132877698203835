import {
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_ERROR,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_ERROR,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_ERROR,
  POST_APPROVE_PAYMENT,
  POST_APPROVE_PAYMENT_SUCCESS,
  POST_APPROVE_PAYMENT_ERROR,
  IS_SUBSCRIPTION_AUTHORIZED,
  IS_SUBSCRIPTION_AUTHORIZED_SUCCESS,
  IS_SUBSCRIPTION_AUTHORIZED_ERROR,
  PAY_SUBSCRIPTION,
  PAY_SUBSCRIPTION_SUCCESS,
  PAY_SUBSCRIPTION_ERROR,
  GET_PUBLIC_KEY,
  GET_PUBLIC_KEY_SUCCESS,
  GET_PUBLIC_KEY_ERROR,
  IMPORT_SUBSCRIPTIONS,
  IMPORT_SUBSCRIPTIONS_SUCCESS,
  IMPORT_SUBSCRIPTIONS_ERROR,
  UPDATE_WATCHED_TIME,
  UPDATE_WATCHED_TIME_SUCCESS,
  UPDATE_WATCHED_TIME_ERROR,
  PATCH_SUBSCRIPTION,
  PATCH_SUBSCRIPTION_SUCCESS,
  PATCH_SUBSCRIPTION_ERROR,
  GET_SUBSCRIPTION_TOKEN,
  GET_SUBSCRIPTION_TOKEN_SUCCESS,
  GET_SUBSCRIPTION_TOKEN_ERROR,
  PATCH_ACCOUNT_ROLE,
  PATCH_ACCOUNT_ROLE_SUCCESS,
  PATCH_ACCOUNT_ROLE_ERROR,
  GET_CERTIFICATE,
  GET_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_ERROR,
  POST_EMAIL_NOTIFICATION,
  POST_EMAIL_NOTIFICATION_SUCCESS,
  POST_EMAIL_NOTIFICATION_ERROR,
} from '../actions';

export const getSubscriptionByWebinarId = (webinarId) => ({
  type: GET_SUBSCRIPTION,
  payload: { webinarId },
});

export const getSubscriptionByWebinarIdSuccess = (subscription) => ({
  type: GET_SUBSCRIPTION_SUCCESS,
  payload: subscription,
});

export const getSubscriptionByWebinarIdError = (message) => ({
  type: GET_SUBSCRIPTION_ERROR,
  payload: message,
});

export const updateWatchedTime = (subscriptionId, timeSpent) => ({
  type: UPDATE_WATCHED_TIME,
  payload: { subscriptionId, timeSpent },
});

export const updateWatchedTimeSuccess = () => ({
  type: UPDATE_WATCHED_TIME_SUCCESS,
});

export const updateWatchedTimeError = (message) => ({
  type: UPDATE_WATCHED_TIME_ERROR,
  payload: message,
});

export const patchSubscription = (subscriptionId, data) => ({
  type: PATCH_SUBSCRIPTION,
  payload: { subscriptionId, data },
});

export const patchSubscriptionSuccess = () => ({
  type: PATCH_SUBSCRIPTION_SUCCESS,
});

export const patchSubscriptionError = (message) => ({
  type: PATCH_SUBSCRIPTION_ERROR,
  payload: message,
});

export const getSubscriptionToken = (subscriptionId, callback) => ({
  type: GET_SUBSCRIPTION_TOKEN,
  payload: { subscriptionId, callback },
});
export const getSubscriptionTokenSuccess = (id) => ({
  type: GET_SUBSCRIPTION_TOKEN_SUCCESS,
  payload: id,
});
export const getSubscriptionTokenError = (message) => ({
  type: GET_SUBSCRIPTION_TOKEN_ERROR,
  payload: { message },
});

export const createSubscription = (webinarId, subscriptionData, callback) => ({
  type: CREATE_SUBSCRIPTION,
  payload: { webinarId, subscriptionData, callback },
});
export const createSubscriptionSuccess = (id) => ({
  type: CREATE_SUBSCRIPTION_SUCCESS,
  payload: id,
});
export const createSubscriptionError = (message) => ({
  type: CREATE_SUBSCRIPTION_ERROR,
  payload: { message },
});

export const getSubscription = (webinarId) => ({
  type: GET_SUBSCRIPTION,
  payload: { webinarId },
});
export const getSubscriptionSuccess = (subscription) => ({
  type: GET_SUBSCRIPTION_SUCCESS,
  payload: subscription,
});
export const getSubscriptionError = (message) => ({
  type: GET_SUBSCRIPTION_ERROR,
  payload: { message },
});

export const deleteSubscription = (subscriptionIds, callback) => ({
  type: DELETE_SUBSCRIPTION,
  payload: { subscriptionIds, callback },
});
export const deleteSubscriptionSuccess = (subscription) => ({
  type: DELETE_SUBSCRIPTION_SUCCESS,
  payload: subscription,
});
export const deleteSubscriptionError = (message) => ({
  type: DELETE_SUBSCRIPTION_ERROR,
  payload: { message },
});

export const postApprovePayment = (subscriptionIds, callback) => ({
  type: POST_APPROVE_PAYMENT,
  payload: { subscriptionIds, callback },
});
export const postApprovePaymentSuccess = (subscription) => ({
  type: POST_APPROVE_PAYMENT_SUCCESS,
  payload: subscription,
});
export const postApprovePaymentError = (message) => ({
  type: POST_APPROVE_PAYMENT_ERROR,
  payload: { message },
});

export const importSubscriptions = (webinarId, file, callback) => ({
  type: IMPORT_SUBSCRIPTIONS,
  payload: { webinarId, file, callback },
});
export const importSubscriptionsSuccess = () => ({
  type: IMPORT_SUBSCRIPTIONS_SUCCESS,
});
export const importSubscriptionsError = (message) => ({
  type: IMPORT_SUBSCRIPTIONS_ERROR,
  payload: { message },
});

export const paySubscription = (paymentData, callback) => ({
  type: PAY_SUBSCRIPTION,
  payload: { paymentData, callback },
});
export const paySubscriptionSuccess = (invoiceUrl) => ({
  type: PAY_SUBSCRIPTION_SUCCESS,
  payload: invoiceUrl,
});
export const paySubscriptionError = (message) => ({
  type: PAY_SUBSCRIPTION_ERROR,
  payload: { message },
});

export const getPagarmePublicKey = () => ({
  type: GET_PUBLIC_KEY,
});
export const getPagarmePublicKeySuccess = (key) => ({
  type: GET_PUBLIC_KEY_SUCCESS,
  payload: key,
});
export const getPagarmePublicKeyError = (message) => ({
  type: GET_PUBLIC_KEY_ERROR,
  payload: { message },
});

export const isSubscriptionAuthorized = (subscriptionId, watchToken) => ({
  type: IS_SUBSCRIPTION_AUTHORIZED,
  payload: { subscriptionId, watchToken },
});

export const isSubscriptionAuthorizedSuccess = () => ({
  type: IS_SUBSCRIPTION_AUTHORIZED_SUCCESS,
});

export const isSubscriptionAuthorizedError = (message) => ({
  type: IS_SUBSCRIPTION_AUTHORIZED_ERROR,
  payload: message,
});

export const patchAccountRole = (subscriptionIds, role, callback) => ({
  type: PATCH_ACCOUNT_ROLE,
  payload: { subscriptionIds, role, callback },
});

export const patchAccountRoleSuccess = (history) => ({
  type: PATCH_ACCOUNT_ROLE_SUCCESS,
  payload: history,
});

export const patchAccountRoleError = (message) => ({
  type: PATCH_ACCOUNT_ROLE_ERROR,
  payload: message,
});

export const getCertificate = (subscriptionId) => ({
  type: GET_CERTIFICATE,
  payload: { subscriptionId },
});

export const getCertificateSuccess = (certificate) => ({
  type: GET_CERTIFICATE_SUCCESS,
  payload: certificate,
});

export const getCertificateError = (message) => ({
  type: GET_CERTIFICATE_ERROR,
});

export const postEmailNotification = (data, callback) => ({
  type: POST_EMAIL_NOTIFICATION,
  payload: { data, callback },
});

export const postEmailNotificationSuccess = (history) => ({
  type: POST_EMAIL_NOTIFICATION_SUCCESS,
  payload: history,
});

export const postEmailNotificationError = (message) => ({
  type: POST_EMAIL_NOTIFICATION_ERROR,
  payload: message,
});
