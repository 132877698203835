import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  WEBINAR_DELETE_PRESENTER,
  WEBINAR_GET_PRESENTERS,
  WEBINAR_PRESENTER_ADD_ITEM,
  addNewPresenterError,
  addNewPresenterSuccess,
  deletePresenterError,
  deletePresenterSuccess,
  getAllPresenters,
  getAllPresentersSuccess,
} from '../actions';

import { getAllPresentersError } from './actions';
import { backendClient } from '../../helpers/http';
import { NotificationManager } from '../../components/common/react-notifications';

const getPresentersRequest = ({ webinarId }) => {
  return backendClient.get(`presenter/webinar/${webinarId}`);
};

function* getPresenters({ payload }) {
  try {
    const response = yield call(getPresentersRequest, payload);
    if (response.status === 200) {
      yield put(getAllPresentersSuccess(response.data));
    } else {
      yield put(getAllPresentersError(response.data.message));
    }
  } catch (error) {
    yield put(getAllPresentersError(error));
  }
}

export function* watchGetPresenters() {
  yield takeEvery(WEBINAR_GET_PRESENTERS, getPresenters);
}

const addNewPresenterRequest = ({ webinarId, name, role, profilePicture }) => {
  const data = new FormData();
  data.append('webinarId', webinarId);
  data.append('name', name);
  data.append('role', role);
  data.append('profilePicture', profilePicture);
  return backendClient.post(`/presenter`, data);
};

function* addNewPresenter({ payload }) {
  try {
    const response = yield call(addNewPresenterRequest, payload);
    if (response.status === 200) {
      yield put(addNewPresenterSuccess());
      yield put(getAllPresenters(payload.webinarId));
      NotificationManager.success('Apresentador adicionado.', 'Sucesso!');
    } else {
      NotificationManager.error(response.data.message, 'Erro!');
      yield put(addNewPresenterError(response.data.message));
    }
  } catch (error) {
    NotificationManager.error(error.message, 'Erro!');
    yield put(getAllPresentersError(error));
  }
}

export function* watchAddNewPresenter() {
  yield takeEvery(WEBINAR_PRESENTER_ADD_ITEM, addNewPresenter);
}

const deletePresenterRequest = ({ presenterId }) => {
  return backendClient.delete(`presenter/${presenterId}`);
};

function* deletePresenter({ payload }) {
  try {
    const response = yield call(deletePresenterRequest, payload);
    if (response.status === 200) {
      yield put(deletePresenterSuccess(payload.presenterId));
      NotificationManager.success('Apresentador deletado.', 'Sucesso!');
    } else {
      yield put(deletePresenterError(response.data.message));
      NotificationManager.error(response.data.message, 'Erro!');
    }
  } catch (error) {
    yield put(getAllPresentersError(error));
  }
}

export function* watchDeletePresenter() {
  yield takeEvery(WEBINAR_DELETE_PRESENTER, deletePresenter);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetPresenters),
    fork(watchAddNewPresenter),
    fork(watchDeletePresenter),
  ]);
}
