import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CREATE_COUPON,
  DELETE_COUPON,
  GET_COUPONS,
  INVALIDATE_COUPON,
} from '../actions';

import {
  getCouponsSuccess,
  getCouponsError,
  createCouponSuccess,
  createCouponError,
  deleteCouponSuccess,
  deleteCouponError,
  invalidateCouponError,
  invalidateCouponSuccess,
} from './actions';

import history from '../../helpers/history';
import { backendClient } from '../../helpers/http';
import { logoutUser } from '../account/actions';
import { NotificationManager } from '../../components/common/react-notifications';

const createCouponRequest = ({ webinarId, couponData }) =>
  backendClient
    .post(`/coupon/${webinarId}`, couponData)
    .then((user) => user)
    .catch((error) => error);

function* createCoupon({ payload }) {
  try {
    const response = yield call(createCouponRequest, payload);
    if (response.status === 200) {
      yield put(createCouponSuccess(response.data));
      NotificationManager.success(
        'Coupon criado com sucesso',
        'Sucesso!',
        3000,
        null,
        null,
        ''
      );
    } else {
      yield put(createCouponError(response.data.message));
      NotificationManager.warning(
        response.data.message,
        'Erro!',
        3000,
        null,
        null,
        ''
      );
    }
  } catch (error) {
    yield put(createCouponError(error));
  }
}
export function* watchCreateCoupon() {
  yield takeEvery(CREATE_COUPON, createCoupon);
}

const deleteCouponRequest = ({ couponId }) =>
  backendClient
    .delete(`/coupon/${couponId}`)
    .then((user) => user)
    .catch((error) => error);

function* deleteCoupon({ payload }) {
  try {
    const response = yield call(deleteCouponRequest, payload);
    if (response.status === 200) {
      yield put(deleteCouponSuccess(response.data));
      NotificationManager.success(
        'Deletado com sucesso, ',
        'Sucesso!',
        3000,
        null,
        null,
        ''
      );
    } else {
      yield put(deleteCouponError(response.data.message));
      NotificationManager.warning(
        'Falha ao deletar coupon',
        'Erro!',
        3000,
        null,
        null,
        ''
      );
    }
  } catch (error) {
    yield put(deleteCouponError(error));
  }
}
export function* watchDeleteCoupon() {
  yield takeEvery(DELETE_COUPON, deleteCoupon);
}

const invalidateCouponRequest = ({ couponId }) =>
  backendClient
    .patch(`/coupon/${couponId}`)
    .then((user) => user)
    .catch((error) => error);

function* invalidateCoupon({ payload }) {
  try {
    const response = yield call(invalidateCouponRequest, payload);
    if (response.status === 200) {
      yield put(invalidateCouponSuccess(response.data));
      NotificationManager.success(
        'Invalidado com sucesso, ',
        'Sucesso!',
        3000,
        null,
        null,
        ''
      );
    } else {
      yield put(invalidateCouponError(response.data.message));
      NotificationManager.warning(
        'Falha ao invalidar coupon',
        'Erro!',
        3000,
        null,
        null,
        ''
      );
    }
  } catch (error) {
    yield put(invalidateCouponError(error));
  }
}
export function* watchInvalidateCoupon() {
  yield takeEvery(INVALIDATE_COUPON, invalidateCoupon);
}

const getCouponsRequest = ({ webinarId }) =>
  backendClient
    .get(`/coupon/${webinarId}`)
    .then((user) => user)
    .catch((error) => error);

function* getCoupons({ payload }) {
  try {
    const response = yield call(getCouponsRequest, payload);
    if (response.status === 200) {
      yield put(getCouponsSuccess(response.data));
    } else {
      yield put(getCouponsError(response.data.message));
    }
    if (response.status === 401) {
      yield put(logoutUser(history));
    }
  } catch (error) {
    yield put(getCouponsError(error));
  }
}
export function* watchGetCoupons() {
  yield takeEvery(GET_COUPONS, getCoupons);
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateCoupon),
    fork(watchDeleteCoupon),
    fork(watchGetCoupons),
    fork(watchInvalidateCoupon),
  ]);
}
