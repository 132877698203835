import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_RAFFLE_RESULT, CREATE_RAFFLE } from '../actions';
import {
  getRaffleResultSuccess,
  getRaffleResultError,
  createRaffleSuccess,
  createRaffleError,
} from './actions';

import { backendClient } from '../../helpers/http';
import { NotificationManager } from '../../components/common/react-notifications';

const getRaffleResultRequest = ({ webinarId }) => {
  return backendClient.get(`raffle/${webinarId}`);
};

function* getRaffleResult({ payload }) {
  try {
    const response = yield call(getRaffleResultRequest, payload);
    if (response.status === 200) {
      yield put(getRaffleResultSuccess(response.data));
    } else {
      NotificationManager.warning(response.data.message, 'Falha');
      yield put(getRaffleResultError(response.data.message));
    }
  } catch (error) {
    yield put(getRaffleResultError(error.message));
  }
}

export function* watchGetRaffleResult() {
  yield takeEvery(GET_RAFFLE_RESULT, getRaffleResult);
}

const createRaffleRequest = ({ webinarId }) => {
  return backendClient.post(`raffle/${webinarId}`);
};

function* createRaffle({ payload }) {
  try {
    const response = yield call(createRaffleRequest, payload);
    if (response.status === 200) {
      yield put(createRaffleSuccess(response.data));
    } else {
      NotificationManager.warning(response.data.message, 'Falha');
      yield put(createRaffleError(response.data.message));
    }
  } catch (error) {
    yield put(createRaffleError(error.message));
  }
}

export function* watchCreateRaffle() {
  yield takeEvery(CREATE_RAFFLE, createRaffle);
}

export default function* rootSaga() {
  yield all([fork(watchGetRaffleResult), fork(watchCreateRaffle)]);
}
