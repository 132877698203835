import {
  SEND_VIRTUAL_GIFT,
  SEND_VIRTUAL_GIFT_SUCCESS,
  SEND_VIRTUAL_GIFT_ERROR,
} from '../actions';

export const sendVirtualGift = (data) => ({
  type: SEND_VIRTUAL_GIFT,
  payload: data,
});
export const sendVirtualGiftSuccess = (id) => ({
  type: SEND_VIRTUAL_GIFT_SUCCESS,
  payload: id,
});
export const sendVirtualGiftError = (message) => ({
  type: SEND_VIRTUAL_GIFT_ERROR,
  payload: { message },
});
