/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/

export const defaultMenuType = 'menu-default';

export const timerQuestions = 60;
export const timerChat = 3;
export const SUBSCRIBER_AUTH_TIMER = 30;

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

export const defaultLocale = 'pt';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'pt', name: 'Portuguese - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const STREAMING_SERVICES = {
  VIMEO: 'VIMEO',
  YOUTUBE: 'YOUTUBE',
  CUSTOM: 'CUSTOM',
};

export const streamingServices = [
  { value: 0, label: STREAMING_SERVICES.VIMEO },
  { value: 1, label: STREAMING_SERVICES.YOUTUBE },
  { value: 2, label: STREAMING_SERVICES.CUSTOM },
];

export const WebinarOptions = {
  None: 0,
  Free: 1,
  ChatAvailable: 2,
  QuestionsAvailable: 4,
  SurveysAvailable: 8,
  ModeratedChat: 16,
  RaffleAvailable: 32,
  InvoiceAvailable: 64,
  CreditCardAvailable: 128,
  PaypalAvailable: 256,
  CertificateAvailable: 512,
  AcceptanceTerms: 1024,
  GiftAvailable: 2048,
};

export const StandardField = {
  None: 0,
  Email: 1,
  CityAndState: 2,
  Company: 4,
  PhoneNumber: 8,
  Referer: 16,
};

export const statusDic = {
  Started: { text: 'Iniciado', color: 'success' },
  Finished: { text: 'Encerrado', color: 'dark' },
  Canceled: { text: 'Cancelado', color: 'danger' },
  AwaitingStarting: { text: 'Aguardando', color: 'warning' },
};

export const currentUser = {
  id: 1,
  title: '',
  img: '',
  date: '',
  role: '',
};

export const adminRoot = '/app';
export const subscriberRoot = '/userApp';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const OPTIONS_SUBSCRIBER = {
  true: 'Sim',
  moderator: 'Moderador',
  subscriber: 'Inscrito',
};
