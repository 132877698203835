/* eslint-disable no-bitwise */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import fileDownload from 'js-file-download';

import {
  WEBINAR_GET_LIST,
  WEBINAR_ADD_ITEM,
  WEBINAR_UPDATE,
  WEBINAR_GET_ITEM,
  GET_CHECKOUT_LINK,
  CANCEL_WEBINAR,
  UPDATE_LOGO,
  START_WEBINAR,
  WEBINAR_GET_ITEM_BY_SUBID,
  WEBINAR_GET_ITEM_DETAIL,
  WEBINAR_GET_SUBSCRIBERS,
  CLOSE_WEBINAR,
  WEBINAR_DOWNLOAD_REPORT,
  SUBSCRIBER_WEBINAR_GET_LIST,
  UPDATE_PROMO_IMG,
  UPDATE_CERTIFICATE,
} from '../actions';

import {
  getWebinarListSuccess,
  getWebinarList,
  getWebinarListError,
  addWebinarItemSuccess,
  addWebinarItemError,
  updateWebinarItemSuccess,
  updateWebinarItemError,
  getWebinarItemSuccess,
  getWebinarItemError,
  getCheckoutLinkSuccess,
  getCheckoutLinkError,
  cancelWebinarSuccess,
  cancelWebinarError,
  uploadLogoError,
  uploadLogoSuccess,
  uploadPromoImgError,
  uploadPromoImgSuccess,
  getWebinar,
  startWebinarError,
  startWebinarSuccess,
  getWebinarBySubscriberIdSuccess,
  getWebinarBySubscriberIdError,
  getWebinarSubscribersSuccess,
  getWebinarSubscribersError,
  closeWebinarSuccess,
  closeWebinarError,
  downloadWebinarReportSuccess,
  downloadWebinarReportError,
  getSubscribedWebinarListSuccess,
  getSubscribedWebinarListError,
  updateCertificateSuccess,
  updateCertificateError
} from './actions';

import { backendClient } from '../../helpers/http';
import { NotificationManager } from '../../components/common/react-notifications';

const getWebinarListRequest = () =>
  backendClient
    .get('/webinar')
    .then((webinars) => webinars)
    .catch((error) => error);

function* getWebinarListItems() {
  try {
    yield put(getWebinarItemSuccess({}));
    const response = yield call(getWebinarListRequest);
    if (response.status === 200) {
      yield put(getWebinarListSuccess(response.data));
    } else {
      yield put(getWebinarListError(response.data.message));
    }
  } catch (error) {
    yield put(getWebinarListError(error));
  }
}

const getSubscribedWebinarListRequest = () =>
  backendClient
    .get('/webinar/subscribed')
    .then((webinars) => webinars)
    .catch((error) => error);

function* getSubscribedWebinarList() {
  try {
    const response = yield call(getSubscribedWebinarListRequest);
    if (response.status === 200) {
      yield put(getSubscribedWebinarListSuccess(response.data));
    } else {
      yield put(getSubscribedWebinarListError(response.data.message));
    }
  } catch (error) {
    yield put(getSubscribedWebinarListError(error));
  }
}

const addWebinarItemRequest = (item) =>
  backendClient.post('/webinar/create', item);

function* addWebinarItem({ payload }) {
  try {
    const response = yield call(addWebinarItemRequest, payload);

    if (response.status === 200) {
      yield put(addWebinarItemSuccess(response.data));
      yield put(getWebinarList());
    } else {
      NotificationManager.warning(response.data.message, 'Erro');
      yield put(addWebinarItemError(response.data.message));
    }
  } catch (error) {
    yield put(addWebinarItemError(error));
  }
}

const updateWebinarItemRequest = (item) =>
  backendClient.post(`/webinar/${item.webinarId}/update`, item);

function* updateWebinarItem({ payload }) {
  try {
    const response = yield call(updateWebinarItemRequest, payload.item);

    if (response.status === 200) {
      yield put(updateWebinarItemSuccess(response.data));
      NotificationManager.success('Atualização feita com sucesso.', 'Sucesso!');
      if (payload.callback) {
        payload.callback.success();
      }
    } else {
      NotificationManager.warning(
        response.data.message || 'Falha ao fazer atualização do webinar.',
        'Erro na Atualização'
      );
      yield put(
        updateWebinarItemError(
          response.data.message || 'Falha ao fazer atualização do webinar.'
        )
      );
      if (payload.callback) {
        payload.callback.fail();
      }
    }
  } catch (error) {
    yield put(updateWebinarItemError(error));
  }
}

const getWebinarItemRequest = (id) => backendClient.get(`/webinar/${id}`);

function* getWebinarItem({ payload }) {
  try {
    const response = yield call(getWebinarItemRequest, payload);

    if (response.status === 200) {
      yield put(
        getWebinarItemSuccess({ ...response.data, webinarId: payload })
      );
    } else {
      NotificationManager.warning(response.data.message, 'Erro');
      yield put(getWebinarItemError(response.data.message));
    }
  } catch (error) {
    NotificationManager.warning(error.message, 'Erro');
    yield put(getWebinarItemError(error));
  }
}

const getWebinarItemDetailsRequest = (id) =>
  backendClient.get(`/webinar/${id}/detail`);

function* getWebinarItemDetails({ payload }) {
  try {
    const response = yield call(getWebinarItemDetailsRequest, payload);

    if (response.status === 200) {
      yield put(getWebinarItemSuccess(response.data));
    } else {
      yield put(getWebinarItemError(response.data.message));
      NotificationManager.warning(response.data.message, 'Erro');
    }
  } catch (error) {
    yield put(getWebinarItemError(error));
    NotificationManager.warning(error.message, 'Erro');
  }
}

const getWebinarBySubscriberIdRequest = (id) =>
  backendClient.get(`/webinar/${id}/subscription`);

function* getWebinarBySubscriberId({ payload }) {
  try {
    const response = yield call(getWebinarBySubscriberIdRequest, payload);

    if (response.status === 200) {
      yield put(getWebinarBySubscriberIdSuccess(response.data));
    } else {
      yield put(getWebinarBySubscriberIdError(response.data.message));
    }
  } catch (error) {
    yield put(getWebinarBySubscriberIdError(error));
  }
}

const getCheckoutLinkRequest = ({ id }) =>
  backendClient.post(`/pagseguro/createCheckout`, { subscriptionId: id });

function* getCheckoutLink({ payload }) {
  try {
    const response = yield call(getCheckoutLinkRequest, payload);

    if (response.status === 200) {
      yield put(getCheckoutLinkSuccess(response.data));
    } else {
      yield put(getCheckoutLinkError(response.data.message));
    }
  } catch (error) {
    yield put(getCheckoutLinkError(error));
  }
}

const cancelWebinarRequest = ({ id }) =>
  backendClient.post(`/webinar/${id}/cancel`);

function* cancelWebinar({ payload }) {
  try {
    const response = yield call(cancelWebinarRequest, payload);

    if (response.status === 200) {
      yield put(cancelWebinarSuccess(response.data));
      yield put(getWebinarList());
    } else {
      yield put(cancelWebinarError(response.data.message));
    }
  } catch (error) {
    yield put(cancelWebinarError(error));
  }
}

const postWebinarLogoRequest = ({ id, logo }) => {
  const data = new FormData();
  data.append('webinarLogo', logo);
  return backendClient.post(`/webinar/${id}/logo`, data);
};

function* postWebinarLogo({ payload }) {
  try {
    const response = yield call(postWebinarLogoRequest, payload);

    if (response.status === 200) {
      yield put(uploadLogoSuccess(response.data));
      yield put(getWebinar(payload.id));
    } else {
      NotificationManager.warning(response.data.message, 'Erro');
      yield put(uploadLogoError(response.data.message));
    }
  } catch (error) {
    yield put(uploadLogoError(error));
  }
}

const startWebinarRequest = ({ id }) =>
  backendClient.post(`/webinar/${id}/start`);

function* startWebinar({ payload }) {
  try {
    const response = yield call(startWebinarRequest, payload);

    if (response.status === 200) {
      yield put(startWebinarSuccess(response.data));
      yield put(getWebinarList());
    } else {
      NotificationManager.warning(response.data.message, 'Erro');
      yield put(startWebinarError(response.data.message));
    }
  } catch (error) {
    yield put(startWebinarError(error));
  }
}

const getWebinarSubscribersRequest = ({ webinarId }) =>
  backendClient.get(`webinar/${webinarId}/subscriptions`);

function* getWebinarSubscribers({ payload }) {
  try {
    const response = yield call(getWebinarSubscribersRequest, payload);
    if (response.status === 200) {
      yield put(getWebinarSubscribersSuccess(response.data));
    } else {
      NotificationManager.warning(response.data.message, 'Erro');
      yield put(getWebinarSubscribersError(response.data.message));
    }
  } catch (error) {
    yield put(getWebinarSubscribersError(error));
  }
}

const closeWebinarRequest = ({ id }) =>
  backendClient.post(`/webinar/${id}/close`);

function* closeWebinar({ payload }) {
  try {
    const response = yield call(closeWebinarRequest, payload);

    if (response.status === 200) {
      yield put(closeWebinarSuccess(response.data));
      yield put(getWebinarList());
    } else {
      NotificationManager.warning(response.data.message, 'Erro');
      yield put(closeWebinarError(response.data.message));
    }
  } catch (error) {
    yield put(closeWebinarError(error));
  }
}

const downloadWebinarReportRequest = ({ id }) =>
  backendClient.get(`/webinar/${id}/report`, {
    responseType: 'blob',
  });

function* downloadWebinarReport({ payload }) {
  try {
    const response = yield call(downloadWebinarReportRequest, payload);

    if (response.status === 200) {
      yield put(downloadWebinarReportSuccess());
      fileDownload(response.data, `relatorio_${payload.id}.xlsx`);
    } else {
      yield put(downloadWebinarReportError(response.data.message));
    }
  } catch (error) {
    yield put(downloadWebinarReportError(error));
  }
}

const postWebinarPromoImgRequest = ({ id, promoImg }) => {
  const data = new FormData();
  data.append('webinarPromoImg', promoImg);
  return backendClient.post(`/webinar/${id}/promoImg`, data);
};

function* postWebinarPromoImg({ payload }) {
  try {
    const response = yield call(postWebinarPromoImgRequest, payload);

    if (response.status === 200) {
      yield put(uploadPromoImgSuccess(response.data));
      yield put(getWebinar(payload.id));
    } else {
      yield put(uploadPromoImgError(response.data.message));
    }
  } catch (error) {
    yield put(uploadPromoImgError(error));
  }
}

const postWebinarCertificateRequest = ({ id, logo }) => {
  const data = new FormData();
  data.append('certificate', logo);
  return backendClient.post(`/webinar/${id}/certificate`, data);
};

function* postWebinarCertificate({ payload }) {
  try {
    const response = yield call(postWebinarCertificateRequest, payload);

    if (response.status === 200) {
      yield put(updateCertificateSuccess(response.data));
      yield put(getWebinar(payload.id));
    } else {
      NotificationManager.warning(response.data.message, 'Erro');
      yield put(updateCertificateError(response.data.message));
    }
  } catch (error) {
    yield put(updateCertificateError(error));
  }
}

export function* watchUpdateCertificate() {
  yield takeEvery(UPDATE_CERTIFICATE, postWebinarCertificate);
}

export function* watchUpdatePromoImg() {
  yield takeEvery(UPDATE_PROMO_IMG, postWebinarPromoImg);
}

export function* watchSubscribedWebinarList() {
  yield takeEvery(SUBSCRIBER_WEBINAR_GET_LIST, getSubscribedWebinarList);
}

export function* watchDownloadWebinarReport() {
  yield takeEvery(WEBINAR_DOWNLOAD_REPORT, downloadWebinarReport);
}

export function* watchCloseWebinar() {
  yield takeEvery(CLOSE_WEBINAR, closeWebinar);
}

export function* watchGetWebinarSubscribers() {
  yield takeEvery(WEBINAR_GET_SUBSCRIBERS, getWebinarSubscribers);
}

export function* watchGetWebinarItemDetails() {
  yield takeEvery(WEBINAR_GET_ITEM_DETAIL, getWebinarItemDetails);
}

export function* watchStartWebinar() {
  yield takeEvery(START_WEBINAR, startWebinar);
}

export function* watchPostWebinarLogo() {
  yield takeEvery(UPDATE_LOGO, postWebinarLogo);
}

export function* watchCancelWebinar() {
  yield takeEvery(CANCEL_WEBINAR, cancelWebinar);
}

export function* watchCheckoutLink() {
  yield takeEvery(GET_CHECKOUT_LINK, getCheckoutLink);
}

export function* watchGet() {
  yield takeEvery(WEBINAR_GET_ITEM, getWebinarItem);
}

export function* watchGetBySubId() {
  yield takeEvery(WEBINAR_GET_ITEM_BY_SUBID, getWebinarBySubscriberId);
}

export function* watchUpdate() {
  yield takeEvery(WEBINAR_UPDATE, updateWebinarItem);
}

export function* watchGetList() {
  yield takeEvery(WEBINAR_GET_LIST, getWebinarListItems);
}

export function* wathcAddItem() {
  yield takeEvery(WEBINAR_ADD_ITEM, addWebinarItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchSubscribedWebinarList),
    fork(watchGetBySubId),
    fork(watchUpdatePromoImg),
    fork(watchDownloadWebinarReport),
    fork(watchCloseWebinar),
    fork(watchGetWebinarSubscribers),
    fork(watchGetWebinarItemDetails),
    fork(watchPostWebinarLogo),
    fork(watchStartWebinar),
    fork(watchCancelWebinar),
    fork(watchGetList),
    fork(wathcAddItem),
    fork(watchCheckoutLink),
    fork(watchGet),
    fork(watchUpdate),
    fork(watchUpdateCertificate)
  ]);
}
