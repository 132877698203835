import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { POST_PAYPAL_NOTIFICATION } from '../actions';
import {
  postPaypalNotificationSuccess,
  postPaypalNotificationError,
} from './actions';
import { backendClient } from '../../helpers/http';
import { NotificationManager } from '../../components/common/react-notifications';

const postPaypalNotificationRequest = ({ paymentGatewayId, subscriptionId }) =>
  backendClient
    .post(`/paypal/notification`, { paymentGatewayId, subscriptionId })
    .then((user) => user)
    .catch((error) => error);

function* postPaypalNotification({ payload }) {
  const { callback, data } = payload;
  try {
    const res = yield call(postPaypalNotificationRequest, data);
    if (res.status === 200) {
      yield put(postPaypalNotificationSuccess(res.data.invoiceUrl));
      NotificationManager.success(
        'Pagamento efetuado com sucesso!',
        'Sucesso!'
      );
      if (callback.success) {
        callback.success();
      }
    } else {
      yield put(postPaypalNotificationError(res.data.message));
      NotificationManager.warning(res.data.message, 'Erro de Pagamento');
      if (callback.fail) {
        callback.fail();
      }
    }
  } catch (error) {
    NotificationManager.warning('Erro no pagamento!', 'Erro');
    yield put(postPaypalNotificationError(error));
  }
}
export function* watchPostPaypalNotification() {
  yield takeEvery(POST_PAYPAL_NOTIFICATION, postPaypalNotification);
}

export default function* rootSaga() {
  yield all([fork(watchPostPaypalNotification)]);
}
