import {
  WEBINAR_PRESENTER_ADD_ITEM,
  WEBINAR_PRESENTER_ADD_ITEM_SUCCESS,
  WEBINAR_PRESENTER_ADD_ITEM_ERROR,
  WEBINAR_GET_PRESENTERS,
  WEBINAR_GET_PRESENTERS_SUCCESS,
  WEBINAR_GET_PRESENTERS_ERROR,
  WEBINAR_DELETE_PRESENTER,
  WEBINAR_DELETE_PRESENTER_SUCCESS,
  WEBINAR_DELETE_PRESENTER_ERROR,
} from '../actions';

export const addNewPresenter = ({ webinarId, name, role, profilePicture }) => ({
  type: WEBINAR_PRESENTER_ADD_ITEM,
  payload: { webinarId, name, role, profilePicture },
});

export const addNewPresenterSuccess = () => ({
  type: WEBINAR_PRESENTER_ADD_ITEM_SUCCESS,
});

export const addNewPresenterError = (error) => ({
  type: WEBINAR_PRESENTER_ADD_ITEM_ERROR,
  payload: error,
});

export const getAllPresenters = (webinarId) => ({
  type: WEBINAR_GET_PRESENTERS,
  payload: { webinarId },
});

export const getAllPresentersSuccess = (items) => ({
  type: WEBINAR_GET_PRESENTERS_SUCCESS,
  payload: items,
});

export const getAllPresentersError = (error) => ({
  type: WEBINAR_GET_PRESENTERS_ERROR,
  payload: error,
});

export const deletePresenter = (presenterId) => ({
  type: WEBINAR_DELETE_PRESENTER,
  payload: { presenterId },
});

export const deletePresenterSuccess = (presenterId) => ({
  type: WEBINAR_DELETE_PRESENTER_SUCCESS,
  payload: { presenterId },
});

export const deletePresenterError = (error) => ({
  type: WEBINAR_DELETE_PRESENTER_ERROR,
  payload: error,
});
