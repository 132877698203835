import {
  CREATE_RAFFLE_SUCCESS,
  GET_RAFFLE_RESULT,
  GET_RAFFLE_RESULT_ERROR,
  GET_RAFFLE_RESULT_SUCCESS,
  CREATE_RAFFLE_ERROR,
  CREATE_RAFFLE,
} from '../actions';

const INIT_STATE = {
  winners: [],
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RAFFLE_RESULT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_RAFFLE_RESULT_SUCCESS:
      return {
        ...state,
        winners: action.payload,
        error: '',
      };
    case GET_RAFFLE_RESULT:
      return {
        ...state,
        error: '',
      };
    case CREATE_RAFFLE:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_RAFFLE_SUCCESS:
      return {
        ...state,
        winners: [...state.winners, action.payload],
        error: '',
      };
    case CREATE_RAFFLE_ERROR:
      return {
        ...state,
        error: '',
      };
    default:
      return { ...state };
  }
};
