import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
} from '../actions';
import { getCurrentUser } from '../../helpers/Utils';

const INIT_STATE = {
  currentUser: getCurrentUser() || { uid: '', name: '', email: '' },
  userProfile: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_PROFILE:
      return { ...state, userProfile: null, loading: true, error: '' };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfile: action.payload,
        error: '',
      };
    case GET_USER_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        userProfile: null,
        error: action.payload.message,
      };

    default:
      return { ...state };
  }
};
