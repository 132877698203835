/* eslint-disable no-case-declarations */
import {
  GET_CHAT_HISTORY,
  GET_CHAT_HISTORY_SUCCESS,
  GET_CHAT_HISTORY_ERROR,
  GET_QUESTIONS_HISTORY_SUCCESS,
  GET_QUESTIONS_HISTORY_ERROR,
  GET_QUESTIONS_HISTORY,
  CHAT_ADD_MESSAGE,
  SET_WSCONNECTION,
  CHAT_ADD_MODERATED_MESSAGE,
  CHAT_REMOVE_MODERATED_MESSAGE,
  CHAT_ADD_QUESTION,
  CHAT_SEND_MESSAGE_SUCCESS,
  CHAT_SEND_MESSAGE_ERROR,
  CHAT_ADD_USER_TO_ONLINE_LIST,
  CHAT_REMOVE_USER_TO_ONLINE_LIST,
} from '../actions';

const INIT_STATE = {
  chatMessages: [],
  questionMessages: [],
  onlineUsers: new Set(),
  connection: undefined,
  loadingChatHistory: false,
  loadingQuestionsHistory: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHAT_ADD_USER_TO_ONLINE_LIST:
      return {
        ...state,
        onlineUsers: state.onlineUsers.add(action.payload),
      };
    case CHAT_REMOVE_USER_TO_ONLINE_LIST:
      const newSet = new Set(state.onlineUsers);
      newSet.delete(action.payload);
      return {
        ...state,
        onlineUsers: newSet,
      };

    case CHAT_ADD_MESSAGE:
      const filteredMessages = state.chatMessages.filter(
        (m) => m.messageId !== action.payload.messageId
      );
      const messagesWithNewOne = [...filteredMessages, action.payload];
      const startIndex = Math.max(messagesWithNewOne.length - 50, 0);
      return {
        ...state,
        chatMessages: messagesWithNewOne.slice(startIndex),
      };
    case SET_WSCONNECTION:
      return {
        ...state,
        wsConnection: action.payload,
      };
    case CHAT_ADD_MODERATED_MESSAGE:
      const moderatedMessagesWithNewOne = [
        ...state.chatMessages,
        action.payload,
      ];
      const moderatedStartIndex = Math.max(
        moderatedMessagesWithNewOne.length - 50,
        0
      );
      return {
        ...state,
        chatMessages: moderatedMessagesWithNewOne.slice(moderatedStartIndex),
      };
    case CHAT_REMOVE_MODERATED_MESSAGE:
      // eslint-disable-next-line no-case-declarations
      const copy = state.chatMessages.slice();
      copy.splice(action.payload.index, 1);
      return {
        ...state,
        chatMessages: copy,
      };

    case CHAT_ADD_QUESTION:
      return {
        ...state,
        questionMessages: [...state.questionMessages, action.payload],
      };

    case CHAT_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        chatMessages: [...state.chatMessages, action.payload],
      };

    case CHAT_SEND_MESSAGE_ERROR:
      return {
        ...state,
        chatMessages: [...state.chatMessages, action.payload],
      };

    case GET_CHAT_HISTORY:
      return {
        ...state,
        loadingChatHistory: true,
      };

    case GET_CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        loadingChatHistory: false,
        chatMessages: action.payload,
      };

    case GET_CHAT_HISTORY_ERROR:
      return {
        ...state,
        loadingChatHistory: false,
      };
    case GET_QUESTIONS_HISTORY:
      return {
        ...state,
        loadingQuestionsHistory: true,
      };

    case GET_QUESTIONS_HISTORY_SUCCESS:
      return {
        ...state,
        loadingQuestionsHistory: false,
        questionMessages: action.payload,
      };

    case GET_QUESTIONS_HISTORY_ERROR:
      return {
        ...state,
        loadingQuestionsHistory: false,
      };

    default:
      return { ...state };
  }
};
