import {
  WEBINAR_GET_LIST,
  WEBINAR_GET_LIST_SUCCESS,
  WEBINAR_GET_LIST_ERROR,
  WEBINAR_GET_LIST_WITH_FILTER,
  WEBINAR_GET_LIST_WITH_ORDER,
  WEBINAR_GET_LIST_SEARCH,
  WEBINAR_ADD_ITEM,
  WEBINAR_ADD_ITEM_SUCCESS,
  WEBINAR_ADD_ITEM_ERROR,
  WEBINAR_SELECTED_ITEMS_CHANGE,
  WEBINAR_GET_ITEM,
  WEBINAR_GET_ITEM_SUCCESS,
  WEBINAR_GET_ITEM_ERROR,
  GET_CHECKOUT_LINK,
  GET_CHECKOUT_LINK_ERROR,
  GET_CHECKOUT_LINK_SUCCESS,
  CANCEL_WEBINAR,
  CANCEL_WEBINAR_ERROR,
  CANCEL_WEBINAR_SUCCESS,
  UPDATE_LOGO,
  UPDATE_LOGO_ERROR,
  UPDATE_LOGO_SUCCESS,
  WEBINAR_UPDATE,
  WEBINAR_UPDATE_SUCCESS,
  WEBINAR_UPDATE_ERROR,
  START_WEBINAR,
  START_WEBINAR_ERROR,
  START_WEBINAR_SUCCESS,
  WEBINAR_GET_ITEM_BY_SUBID,
  WEBINAR_GET_ITEM_BY_SUBID_SUCCESS,
  WEBINAR_GET_ITEM_BY_SUBID_ERROR,
  WEBINAR_GET_SUBSCRIBERS,
  WEBINAR_GET_SUBSCRIBERS_SUCCESS,
  WEBINAR_GET_SUBSCRIBERS_ERROR,
  CLOSE_WEBINAR,
  CLOSE_WEBINAR_SUCCESS,
  CLOSE_WEBINAR_ERROR,
  WEBINAR_DOWNLOAD_REPORT,
  WEBINAR_DOWNLOAD_REPORT_SUCCESS,
  WEBINAR_DOWNLOAD_REPORT_ERROR,
  SUBSCRIBER_WEBINAR_GET_LIST,
  SUBSCRIBER_WEBINAR_GET_LIST_SUCCESS,
  SUBSCRIBER_WEBINAR_GET_LIST_ERROR,
  SET_CALLBACK_LINK,
  UPDATE_PROMO_IMG,
  UPDATE_PROMO_IMG_SUCCESS,
  UPDATE_PROMO_IMG_ERROR,
  UPDATE_CERTIFICATE_SUCCESS,
  UPDATE_CERTIFICATE_ERROR,
  UPDATE_CERTIFICATE
} from '../actions';

const INIT_STATE = {
  allWebinarItems: [],
  callbackLink: '',
  webinarItems: [],
  presenters: [],
  metrics: {
    totalWebinars: 0,
    totalSubscribers: 0,
    totalAccesses: 0,
    totalBalance: 0,
  },
  subscriptions: [],
  currWebinar: {},
  alreadyGotLink: false,
  subscribeed: false,
  error: '',
  checkoutLink: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  labels: [
    { label: 'EDUCATION', color: 'secondary' },
    { label: 'NEW FRAMEWORK', color: 'primary' },
    { label: 'PERSONAL', color: 'info' },
  ],
  orderColumns: [
    { column: 'title', label: 'Titulo' },
    { column: 'category', label: 'Categoria' },
    { column: 'status', label: 'Status' },
    { column: 'label', label: 'Label' },
  ],
  categories: ['Youtube', 'Vimeo'],
  selectedItems: [],
  loadingList: false,
  loadedList: false,
  loadingAddItem: false,
  loadingGetItem: false,
  loadingSubscribe: false,
  loadingCheckout: false,
  loadingLogo: false,
  loadingCertificate: false,
  loadingUpdate: false,
  loadingMetrics: false,
  loadingSubscriptions: false,
  loadingStart: false,
  loadingCancel: false,
  loadingClose: false,
  loadingWebinarReport: false,
  addedNewPresenter: false,
  loadingPresenters: false,
  deletedPresenter: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WEBINAR_GET_LIST:
      return { ...state, loadingList: true };

    case WEBINAR_GET_LIST_SUCCESS:
      return {
        ...state,
        loadingList: false,
        allWebinarItems: action.payload,
        webinarItems: action.payload,
      };

    case WEBINAR_GET_LIST_ERROR:
      return { ...state, loadingList: false, error: action.payload };

    case SUBSCRIBER_WEBINAR_GET_LIST:
      return { ...state, loadedList: false };

    case SUBSCRIBER_WEBINAR_GET_LIST_SUCCESS:
      return {
        ...state,
        loadedList: true,
        allWebinarItems: action.payload,
      };

    case SUBSCRIBER_WEBINAR_GET_LIST_ERROR:
      return { ...state, loadedList: false, error: action.payload };

    case WEBINAR_DOWNLOAD_REPORT:
      return { ...state, loadingWebinarReport: true };

    case WEBINAR_DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        loadingWebinarReport: false,
      };

    case WEBINAR_DOWNLOAD_REPORT_ERROR:
      return { ...state, loadingWebinarReport: false, error: action.payload };

    case WEBINAR_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return {
          ...state,
          loading: true,
          webinarItems: state.allWebinarItems,
          filter: null,
        };
      }
      const filteredItems = state.allWebinarItems.filter(
        (item) => item[action.payload.column] === action.payload.value
      );
      return {
        ...state,
        loading: true,
        webinarItems: filteredItems,
        filter: {
          column: action.payload.column,
          value: action.payload.value,
        },
      };

    case WEBINAR_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return {
          ...state,
          loading: true,
          webinarItems: state.webinarItems,
          orderColumn: null,
        };
      }
      const sortedItems = state.webinarItems.sort((a, b) => {
        if (a[action.payload] < b[action.payload]) return -1;
        if (a[action.payload] > b[action.payload]) return 1;
        return 0;
      });
      return {
        ...state,
        loading: true,
        webinarItems: sortedItems,
        orderColumn: state.orderColumns.find(
          (x) => x.column === action.payload
        ),
      };

    case WEBINAR_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, webinarItems: state.allWebinarItems };
      }
      const keyword = action.payload.toLowerCase();
      const searchItems = state.allWebinarItems.filter(
        (item) =>
          item.title.toLowerCase().indexOf(keyword) > -1 ||
          item.detail.toLowerCase().indexOf(keyword) > -1 ||
          item.status.toLowerCase().indexOf(keyword) > -1 ||
          item.category.toLowerCase().indexOf(keyword) > -1 ||
          item.label.toLowerCase().indexOf(keyword) > -1
      );
      return {
        ...state,
        loading: true,
        webinarItems: searchItems,
        searchKeyword: action.payload,
      };

    case WEBINAR_ADD_ITEM:
      return { ...state, loadingAddItem: true };

    case WEBINAR_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loadingAddItem: false,
        allWebinarItems: state.allWebinarItems.concat(action.payload),
        webinarItems: state.webinarItems.concat(action.payload),
      };

    case WEBINAR_ADD_ITEM_ERROR:
      return { ...state, loadingAddItem: false, error: action.payload };

    case WEBINAR_GET_ITEM:
      return { ...state, currWebinar: {}, loadingGetItem: true };

    case WEBINAR_GET_ITEM_SUCCESS:
      return {
        ...state,
        loadingGetItem: false,
        currWebinar: action.payload,
      };

    case WEBINAR_GET_ITEM_ERROR:
      return { ...state, loadingGetItem: false, error: action.payload };

    case WEBINAR_GET_ITEM_BY_SUBID:
      return { ...state, loadingGetItem: true };

    case WEBINAR_GET_ITEM_BY_SUBID_SUCCESS:
      return {
        ...state,
        loadingGetItem: false,
        currWebinar: action.payload,
      };

    case WEBINAR_GET_ITEM_BY_SUBID_ERROR:
      return { ...state, loadingGetItem: false, error: action.payload };

    case WEBINAR_SELECTED_ITEMS_CHANGE:
      return { ...state, selectedItems: action.payload };

    case GET_CHECKOUT_LINK:
      return {
        ...state,
        loadingCheckout: true,
        alreadyGotLink: true,
      };
    case GET_CHECKOUT_LINK_ERROR:
      return { ...state, loadingCheckout: false, error: action.payload };

    case GET_CHECKOUT_LINK_SUCCESS:
      return { ...state, loadingCheckout: false, checkoutLink: action.payload };

    case CANCEL_WEBINAR:
      return {
        ...state,
        loadingCancel: true,
      };
    case CANCEL_WEBINAR_ERROR:
      return { ...state, loadingCancel: false, error: action.payload };

    case CANCEL_WEBINAR_SUCCESS:
      return { ...state, loadingCancel: false };

    case START_WEBINAR:
      return {
        ...state,
        loadingStart: true,
      };

    case START_WEBINAR_SUCCESS:
      return { ...state, loadingStart: false };

    case START_WEBINAR_ERROR:
      return { ...state, loadingStart: false, error: action.payload };

    case CLOSE_WEBINAR:
      return {
        ...state,
        loadingClose: true,
      };

    case CLOSE_WEBINAR_SUCCESS:
      return { ...state, loadingClose: false };

    case CLOSE_WEBINAR_ERROR:
      return { ...state, loadingClose: false, error: action.payload };

    case UPDATE_LOGO:
      return {
        ...state,
        loadingLogo: true,
      };

    case UPDATE_LOGO_SUCCESS:
      return { ...state, loadingLogo: false };

    case UPDATE_LOGO_ERROR:
      return { ...state, loadingLogo: false, error: action.payload };

    case UPDATE_PROMO_IMG:
      return {
        ...state,
        loadingLogo: true,
      };

    case UPDATE_PROMO_IMG_SUCCESS:
      return { ...state, loadingLogo: false };

    case UPDATE_PROMO_IMG_ERROR:
      return { ...state, loadingLogo: false, error: action.payload };

    case WEBINAR_UPDATE:
      return {
        ...state,
        loadingUpdate: true,
      };
    case WEBINAR_UPDATE_SUCCESS:
      return { ...state, loadingUpdate: false };

    case WEBINAR_UPDATE_ERROR:
      return { ...state, loadingUpdate: false, error: action.payload };

    case WEBINAR_GET_SUBSCRIBERS:
      return {
        ...state,
        loadingSubscriptions: true,
      };
    case WEBINAR_GET_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        loadingSubscriptions: false,
        subscriptions: action.payload,
      };

    case WEBINAR_GET_SUBSCRIBERS_ERROR:
      return { ...state, loadingSubscriptions: false, error: action.payload };

    case SET_CALLBACK_LINK:
      return { ...state, callbackLink: action.payload };

    case UPDATE_CERTIFICATE:
      return {
        ...state,
        loadingCertificate: true,
      };

    case UPDATE_CERTIFICATE_SUCCESS:
      return { ...state, loadingCertificate: false };

    case UPDATE_CERTIFICATE_ERROR:
      return { ...state, loadingCertificate: false, error: action.payload };

    default:
      return { ...state };
  }
};
