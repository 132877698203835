import {
  UPDATE_PAYPAL_PAYMENT_NOTIFICATION,
  UPDATE_PAYPAL_PAYMENT_NOTIFICATION_SUCCESS,
  UPDATE_PAYPAL_PAYMENT_NOTIFICATION_ERROR,
  POST_PAGARME_PROCESS_PAYMENT,
  POST_PAGARME_PROCESS_PAYMENT_SUCCESS,
  POST_PAGARME_PROCESS_PAYMENT_ERROR,
  POST_PAGARME_RELEASE_PAYMENT,
  POST_PAGARME_RELEASE_PAYMENT_SUCCESS,
  POST_PAGARME_RELEASE_PAYMENT_ERROR,
} from '../actions';

export const updateSubscriptionPaymentStatus = (data, callback) => ({
  type: UPDATE_PAYPAL_PAYMENT_NOTIFICATION,
  payload: { data, callback },
});

export const updateSubscriptionPaymentStatusSuccess = () => ({
  type: UPDATE_PAYPAL_PAYMENT_NOTIFICATION_SUCCESS,
});

export const updateSubscriptionPaymentStatusError = (message) => ({
  type: UPDATE_PAYPAL_PAYMENT_NOTIFICATION_ERROR,
  payload: { message },
});

export const postPagarmeProcessPayment = (data, callback) => ({
  type: POST_PAGARME_PROCESS_PAYMENT,
  payload: { data, callback },
});

export const postPagarmeProcessPaymentSuccess = () => ({
  type: POST_PAGARME_PROCESS_PAYMENT_SUCCESS,
});

export const postPagarmeProcessPaymentError = (message) => ({
  type: POST_PAGARME_PROCESS_PAYMENT_ERROR,
  payload: { message },
});

export const postPagarmeReleasePayment = (data, callback) => ({
  type: POST_PAGARME_RELEASE_PAYMENT,
  payload: { data, callback },
});

export const postPagarmeReleasePaymentSuccess = () => ({
  type: POST_PAGARME_RELEASE_PAYMENT_SUCCESS,
});

export const postPagarmeReleasePaymentError = (message) => ({
  type: POST_PAGARME_RELEASE_PAYMENT_ERROR,
  payload: { message },
});
