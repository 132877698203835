import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_CHAT_HISTORY, GET_QUESTIONS_HISTORY } from '../actions';
import {
  getChatHistorySuccess,
  getChatHistoryError,
  getQuestionsHistoryError,
  getQuestionsHistorySuccess,
} from './actions';
import { backendClient } from '../../helpers/http';

const getChatHistoryRequest = ({ chatRoomId }) =>
  backendClient.get(`/message/${chatRoomId}/chatroom`);

function* getChatHistory({ payload }) {
  try {
    const response = yield call(getChatHistoryRequest, payload);
    if (response.status === 200) {
      yield put(getChatHistorySuccess(response.data));
    }
  } catch (error) {
    yield put(getChatHistoryError(error));
  }
}

export function* watchGetChatHistory() {
  yield takeEvery(GET_CHAT_HISTORY, getChatHistory);
}

const getQuestionsHistoryRequest = ({ webinarId, subscriptionId }) =>
  backendClient.get(`/webinar/${webinarId}/questions`, {
    params: { subscriptionId },
  });

function* getQuestionsHistory({ payload }) {
  try {
    const response = yield call(getQuestionsHistoryRequest, payload);
    if (response.status === 200) {
      yield put(getQuestionsHistorySuccess(response.data));
    }
  } catch (error) {
    yield put(getQuestionsHistoryError(error));
  }
}

export function* watchGetQuestionsHistory() {
  yield takeEvery(GET_QUESTIONS_HISTORY, getQuestionsHistory);
}

export default function* rootSaga() {
  yield all([fork(watchGetChatHistory), fork(watchGetQuestionsHistory)]);
}
