import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import jwt from 'jwt-decode';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

import { adminRoot, subscriberRoot } from '../../constants/defaultValues';
import { setCurrentUser, setAccessToken } from '../../helpers/Utils';
import { backendClient } from '../../helpers/http';
import { NotificationManager } from '../../components/common/react-notifications';

const loginWithEmailPasswordAsync = (userData) =>
  backendClient
    .post('/account/token', userData)
    .then((user) => user)
    .catch((error) => ({
      data: { success: false, message: error.message },
    }));

function* loginWithEmailPassword({ payload }) {
  const { history, callbackLink } = payload;
  try {
    const response = yield call(loginWithEmailPasswordAsync, payload.user);

    if (response.status === 200) {
      // eslint-disable-next-line camelcase
      const { email, groupsid, unique_name, role } = jwt(
        response.data.accessToken
      );
      const item = { uid: groupsid, name: unique_name, email, role };
      setCurrentUser(item);
      setAccessToken(response.data.accessToken);
      yield put(loginUserSuccess(item));
      if (role === 'admin') {
        history.push(adminRoot);
      } else {
        history.push(callbackLink || subscriberRoot);
      }
    } else {
      yield put(
        loginUserError(response.data.Message || 'Falha ao tentar fazer login!')
      );
      NotificationManager.warning(
        response.data.message || 'Usuário ou senha incorretos.',
        'Erro ao fazer login.'
      );
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}
export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const registerWithEmailPasswordAsync = (registrationData) =>
  backendClient
    .post('/account/signup', registrationData)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const {
    email,
    firstName,
    lastName,
    password,
    confirmPassword,
  } = payload.user;
  try {
    const response = yield call(registerWithEmailPasswordAsync, {
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
    });
    if (response.status === 200) {
      yield put(registerUserSuccess(payload.user));
      NotificationManager.success(
        response.data.message || 'Por favor, faça o login com sua nova conta!',
        'Sucesso!'
      );
      payload.history.push('/login');
    } else {
      yield put(registerUserError(response.data.message));
      NotificationManager.warning(response.data.message, 'Erro');
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const logoutAsync = (history) => {
  return history.push('/user/login');
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  setAccessToken();
  yield call(logoutAsync, history);
}
export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const forgotPasswordAsync = async (email) =>
  backendClient
    .post('/account/forgot-password', { email })
    .then((user) => user)
    .catch((error) => error);

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const response = yield call(forgotPasswordAsync, email);
    if (response.status === 200) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(response.data.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const resetPasswordAsync = async (confirmPassword, password) =>
  backendClient
    .post('account/reset-password', {
      password,
      confirmPassword,
    })
    .then((user) => user)
    .catch((error) => error);

function* resetPassword({ payload }) {
  const { password, confirmPassword, history } = payload;
  try {
    const response = yield call(resetPasswordAsync, confirmPassword, password);
    if (response.status === 401) {
      yield put(resetPasswordError('E-mail de renovação de senha expirado'));
    } else if (response.status === 200) {
      yield put(resetPasswordSuccess('success'));
      history.push('/user/login');
    } else {
      yield put(resetPasswordError(response.data.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}
export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default function* rootSaga() {
  yield all([
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
  ]);
}
