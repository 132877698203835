import {
  UPDATE_PAYPAL_PAYMENT_NOTIFICATION,
  UPDATE_PAYPAL_PAYMENT_NOTIFICATION_SUCCESS,
  UPDATE_PAYPAL_PAYMENT_NOTIFICATION_ERROR,
  POST_PAGARME_PROCESS_PAYMENT,
  POST_PAGARME_PROCESS_PAYMENT_SUCCESS,
  POST_PAGARME_PROCESS_PAYMENT_ERROR,
  POST_PAGARME_RELEASE_PAYMENT,
  POST_PAGARME_RELEASE_PAYMENT_SUCCESS,
  POST_PAGARME_RELEASE_PAYMENT_ERROR,
} from '../actions';

const INIT_STATE = {
  error: '',
  loadingUpdatePaypalPaymentNotification: false,
  loadingPostPagarmeProcessPayment: false,
  loadingPostPagarmeReleasePayment: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PAYPAL_PAYMENT_NOTIFICATION:
      return {
        ...state,
        loadingUpdatePaypalPaymentNotification: true,
        error: '',
      };
    case UPDATE_PAYPAL_PAYMENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingUpdatePaypalPaymentNotification: false,
        error: '',
      };
    case UPDATE_PAYPAL_PAYMENT_NOTIFICATION_ERROR:
      return {
        ...state,
        loadingUpdatePaypalPaymentNotification: false,
        error: action.payload.message,
      };
    case POST_PAGARME_PROCESS_PAYMENT:
      return {
        ...state,
        loadingPostPagarmeProcessPayment: true,
        error: '',
      };
    case POST_PAGARME_PROCESS_PAYMENT_SUCCESS:
      return {
        ...state,
        loadingPostPagarmeProcessPayment: false,
        error: '',
      };
    case POST_PAGARME_PROCESS_PAYMENT_ERROR:
      return {
        ...state,
        loadingPostPagarmeProcessPayment: false,
        error: action.payload.message,
      };
    case POST_PAGARME_RELEASE_PAYMENT:
      return {
        ...state,
        loadingPostPagarmeReleasePayment: true,
        error: '',
      };
    case POST_PAGARME_RELEASE_PAYMENT_SUCCESS:
      return {
        ...state,
        loadingPostPagarmeReleasePayment: false,
        error: '',
      };
    case POST_PAGARME_RELEASE_PAYMENT_ERROR:
      return {
        ...state,
        loadingPostPagarmeReleasePayment: false,
        error: action.payload.message,
      };

    default:
      return { ...state };
  }
};
