import jwt from 'jwt-decode';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  CREATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GET_PUBLIC_KEY,
  GET_SUBSCRIPTION,
  IMPORT_SUBSCRIPTIONS,
  isSubscriptionAuthorizedError,
  isSubscriptionAuthorizedSuccess,
  IS_SUBSCRIPTION_AUTHORIZED,
  PATCH_SUBSCRIPTION,
  PAY_SUBSCRIPTION,
  POST_APPROVE_PAYMENT,
  GET_SUBSCRIPTION_TOKEN,
  UPDATE_WATCHED_TIME,
  PATCH_ACCOUNT_ROLE,
  GET_CERTIFICATE,
  POST_EMAIL_NOTIFICATION,
} from '../actions';

import {
  getSubscriptionSuccess,
  getSubscriptionError,
  createSubscriptionSuccess,
  createSubscriptionError,
  paySubscriptionError,
  paySubscriptionSuccess,
  getPagarmePublicKeySuccess,
  getPagarmePublicKeyError,
  deleteSubscriptionSuccess,
  deleteSubscriptionError,
  postApprovePaymentSuccess,
  postApprovePaymentError,
  importSubscriptionsSuccess,
  importSubscriptionsError,
  getSubscriptionTokenSuccess,
  getSubscriptionTokenError,
  updateWatchedTimeSuccess,
  updateWatchedTimeError,
  patchSubscriptionSuccess,
  patchSubscriptionError,
  getSubscriptionByWebinarIdSuccess,
  getSubscriptionByWebinarIdError,
  patchAccountRoleSuccess,
  patchAccountRoleError,
  getCertificateSuccess,
  getCertificateError,
  postEmailNotificationSuccess,
  postEmailNotificationError,
} from './actions';

import history from '../../helpers/history';
import { backendClient } from '../../helpers/http';
import { logoutUser } from '../account/actions';
import { setAccessToken, setCurrentUser } from '../../helpers/Utils';
import { NotificationManager } from '../../components/common/react-notifications';

const getSubscriptionTokenRequest = ({ subscriptionId }) =>
  backendClient
    .get(`/subscription/${subscriptionId}/token`)
    .then((user) => user)
    .catch((error) => error);

function* getSubscriptionToken({ payload }) {
  try {
    const response = yield call(getSubscriptionTokenRequest, payload);
    if (response.status === 200) {
      yield put(getSubscriptionTokenSuccess(response.data.publicToken));
    } else {
      yield put(getSubscriptionTokenError(response.data.message));
    }
  } catch (error) {
    yield put(getSubscriptionTokenError(error));
  }
}
export function* watchGetSubscriptionToken() {
  yield takeEvery(GET_SUBSCRIPTION_TOKEN, getSubscriptionToken);
}

const updateWatchedTimeRequest = ({ subscriptionId, timeSpent }) => {
  const params = new FormData();
  params.append('timeSpent', timeSpent);
  return backendClient.post(`subscription/${subscriptionId}/watched`, params);
};

function* updateWatchedTime({ payload }) {
  try {
    const response = yield call(updateWatchedTimeRequest, payload);
    if (response.status === 200) {
      yield put(updateWatchedTimeSuccess());
    }
    if (response.status === 401) {
      yield put(updateWatchedTimeError('UnknowError'));
    }
  } catch (error) {
    yield put(updateWatchedTimeError(error.message));
  }
}

export function* watchUpdateWatchedTime() {
  yield takeEvery(UPDATE_WATCHED_TIME, updateWatchedTime);
}

const patchSubscriptionRequest = ({ subscriptionId, data }) =>
  backendClient.post(`subscription/${subscriptionId}`, { data });

function* patchSubscription({ payload }) {
  try {
    const response = yield call(patchSubscriptionRequest, payload);
    if (response.status === 200) {
      yield put(patchSubscriptionSuccess());
    }
    if (response.status === 401) {
      yield put(patchSubscriptionError('UnknowError'));
    }
  } catch (error) {
    yield put(patchSubscriptionError(error.message));
  }
}

export function* watchPatchSubscription() {
  yield takeEvery(PATCH_SUBSCRIPTION, patchSubscription);
}

const getSubscriptionByWebinarIdRequest = ({ webinarId }) =>
  backendClient.get(`/subscription/${webinarId}`);

function* getSubscriptionByWebinarId({ payload }) {
  try {
    const response = yield call(getSubscriptionByWebinarIdRequest, payload);
    if (!response.data.message) {
      yield put(getSubscriptionByWebinarIdSuccess(response.data));
    }
  } catch (error) {
    yield put(getSubscriptionByWebinarIdError(error));
  }
}

export function* watchGetSubscriber() {
  yield takeEvery(GET_SUBSCRIPTION, getSubscriptionByWebinarId);
}

const getPagarmePublicKeyRequest = () =>
  backendClient
    .get(`/payments/public-token`)
    .then((user) => user)
    .catch((error) => error);

function* getPagarmePublicKey({ payload }) {
  try {
    const response = yield call(getPagarmePublicKeyRequest, payload);
    if (response.status === 200) {
      yield put(getPagarmePublicKeySuccess(response.data.publicToken));
    } else {
      yield put(getPagarmePublicKeyError(response.data.message));
    }
  } catch (error) {
    yield put(getPagarmePublicKeyError(error));
  }
}
export function* watchPagarmeGetPublicKey() {
  yield takeEvery(GET_PUBLIC_KEY, getPagarmePublicKey);
}

const paySubscriptionRequest = (paymentData) =>
  backendClient
    .post(`/pagarme/process-payment`, paymentData)
    .then((user) => user)
    .catch((error) => error);

function* paySubscription({ payload }) {
  const { callback, paymentData } = payload;
  try {
    const response = yield call(paySubscriptionRequest, paymentData);
    if (response.status === 200) {
      yield put(paySubscriptionSuccess(response.data.invoiceUrl));
      NotificationManager.success(
        response.data.invoiceUrl
          ? 'Aguardando processamento do boleto.'
          : 'Pagamento efetuado com sucesso!',
        'Sucesso!'
      );
      if (callback) {
        callback.success(response.data.invoiceUrl);
      }
    } else {
      yield put(paySubscriptionError(response.data.message));
      NotificationManager.warning(response.data.message, 'Erro de Pagamento');
      callback.fail();
    }
  } catch (error) {
    NotificationManager.warning('Erro no pagamento!', 'Erro');
    callback.fail();
    yield put(paySubscriptionError(error));
  }
}
export function* watchPaySubscription() {
  yield takeEvery(PAY_SUBSCRIPTION, paySubscription);
}

const createSubscriptionRequest = ({ webinarId, subscriptionData }) =>
  backendClient
    .post(`/subscription/${webinarId}`, subscriptionData)
    .then((user) => user)
    .catch((error) => error);

function* createSubscription({ payload }) {
  try {
    const response = yield call(createSubscriptionRequest, payload);
    if (response.status === 200) {
      if (response.data.accessToken) {
        setAccessToken(response.data.accessToken);
        const { email, groupsid, unique_name, role } = jwt(
          response.data.accessToken
        );
        const item = { uid: groupsid, name: unique_name, email, role };
        setCurrentUser(item);
      }
      yield put(createSubscriptionSuccess(response.data.subscriptionId));
      if (payload.callback) {
        payload.callback.success(response.data.subscriptionId);
      }
    } else if (response.status === 409) {
      setAccessToken();
      setCurrentUser();
      NotificationManager.warning(
        response.data.message,
        'Erro de Registro',
        3000,
        null,
        null,
        ''
      );
      payload.callback.fail(response.status);
      return;
    } else {
      yield put(createSubscriptionError(response.data.message));
      NotificationManager.warning(
        response.data.message,
        'Erro de Registro',
        3000,
        null,
        null,
        ''
      );
      if (payload.callback) {
        payload.callback.fail(response.status);
      }
    }
  } catch (error) {
    yield put(createSubscriptionError(error));
  }
}
export function* watchCreateSubscription() {
  yield takeEvery(CREATE_SUBSCRIPTION, createSubscription);
}

const getSubscriptionRequest = ({ webinarId }) =>
  backendClient
    .get(`/subscription/${webinarId}`)
    .then((user) => user)
    .catch((error) => error);

function* getSubscription({ payload }) {
  try {
    const response = yield call(getSubscriptionRequest, payload);
    if (response.status === 200) {
      yield put(getSubscriptionSuccess(response.data));
    } else {
      yield put(getSubscriptionError(response.data.message));
    }
    if (response.status === 401) {
      yield put(logoutUser(history));
    }
  } catch (error) {
    yield put(getSubscriptionError(error));
  }
}
export function* watchGetSubscription() {
  yield takeEvery(GET_SUBSCRIPTION, getSubscription);
}

const deleteSubscriptionRequest = ({ subscriptionIds }) =>
  backendClient
    .delete(`/subscription`, { data: subscriptionIds })
    .then((user) => user)
    .catch((error) => error);

function* deleteSubscription({ payload }) {
  try {
    const response = yield call(deleteSubscriptionRequest, payload);
    if (response.status === 200) {
      yield put(deleteSubscriptionSuccess(response.data));
      if (payload.callback) {
        payload.callback.success();
      }
    } else {
      yield put(deleteSubscriptionError(response.data.message));
      if (payload.callback) {
        payload.callback.fail();
      }
    }
    if (response.status === 401) {
      yield put(logoutUser(history));
    }
  } catch (error) {
    yield put(deleteSubscriptionError(error));
    if (payload.callback) {
      payload.callback.fail();
    }
  }
}
export function* watchDeleteSubscription() {
  yield takeEvery(DELETE_SUBSCRIPTION, deleteSubscription);
}

const postApprovePaymentRequest = ({ subscriptionIds }) =>
  backendClient
    .post(`/subscription/approve-payment`, subscriptionIds)
    .then((user) => user)
    .catch((error) => error);

function* approvePayment({ payload }) {
  try {
    const response = yield call(postApprovePaymentRequest, payload);
    if (response.status === 200) {
      yield put(postApprovePaymentSuccess(response.data));
      if (payload.callback) {
        payload.callback.success();
        NotificationManager.success(response.data.message, 'Sucesso');
      }
    } else {
      yield put(postApprovePaymentError(response.data.message));
      if (payload.callback) {
        payload.callback.fail();
        NotificationManager.error(response.data.message, 'Error');
      }
    }
    if (response.status === 401) {
      yield put(logoutUser(history));
      if (payload.callback) {
        payload.callback.fail();
      }
    }
  } catch (error) {
    yield put(postApprovePaymentError(error));
    if (payload.callback) {
      payload.callback.fail();
    }
  }
}
export function* watchApprovePayment() {
  yield takeEvery(POST_APPROVE_PAYMENT, approvePayment);
}

const isSubscriptionAuthorizedRequest = ({ subscriptionId, watchToken }) =>
  backendClient.get(`/subscription/${subscriptionId}/token`, {
    params: { watchToken },
  });

function* isSubscriptionAuthorized({ payload }) {
  try {
    const response = yield call(isSubscriptionAuthorizedRequest, payload);
    if (response.status === 200) {
      yield put(isSubscriptionAuthorizedSuccess(response.data));
    }
    if (response.status === 401) {
      setAccessToken();
      yield put(
        isSubscriptionAuthorizedError(
          'O link dessa live foi compartilhado e está sendo usado por outras pessoas. Se você não compartilhou esse link, entre em contato com o suporte: suporte@forlive.io'
        )
      );
      yield put(logoutUser(history));
    }
  } catch (error) {
    yield put(isSubscriptionAuthorizedError(error.message));
  }
}

export function* watchIsSubscriberAuthorized() {
  yield takeEvery(IS_SUBSCRIPTION_AUTHORIZED, isSubscriptionAuthorized);
}

const postSubscriptionsFileRequest = ({ webinarId, file }) => {
  const data = new FormData();
  data.append('subscriptionsFile', file);
  data.append('webinarId', webinarId);
  return backendClient.post(`/subscription/import`, data);
};

function* postSubscriptionsFile({ payload }) {
  const { callback } = payload;
  try {
    const response = yield call(postSubscriptionsFileRequest, payload);

    if (response.status === 200) {
      yield put(importSubscriptionsSuccess(response.data));
      NotificationManager.success(response.data.message, 'Sucesso');
      if (callback) {
        callback.success();
      }
    } else {
      yield put(importSubscriptionsError(response.data.message));
      NotificationManager.error(response.data.message, 'Erro');
      if (callback) {
        callback.fail();
      }
    }
  } catch (error) {
    yield put(importSubscriptionsError(error));
    NotificationManager.error(error, 'Erro');
  }
}

export function* watchPostSubscriptionsFile() {
  yield takeEvery(IMPORT_SUBSCRIPTIONS, postSubscriptionsFile);
}

const patchChangeAccountRoleRequest = ({ subscriptionIds, role }) =>
  backendClient.patch(`/account/change-role`, {
    subscriptionIds,
    role,
  });

function* patchChangeAccountRole({ payload }) {
  const { callback } = payload;
  try {
    const response = yield call(patchChangeAccountRoleRequest, payload);

    if (response.status === 200) {
      yield put(patchAccountRoleSuccess());
      NotificationManager.success(
        `${
          payload.role === 'moderator' ? 'Moderadores' : 'Inscritos'
        } definidos com sucesso`,
        'Sucesso'
      );
      if (callback) {
        callback.success();
      }
    } else {
      yield put(patchAccountRoleError(response.data.message));
      NotificationManager.error(response.data.message, 'Erro');
      if (callback) {
        callback.fail();
      }
    }
  } catch (error) {
    yield put(patchAccountRoleError(error));
    NotificationManager.error(error, 'Erro');
  }
}

export function* watchPatchChangeAccountRole() {
  yield takeEvery(PATCH_ACCOUNT_ROLE, patchChangeAccountRole);
}

const getCertificateRequest = ({ subscriptionId }) =>
  backendClient.get(`/subscription/${subscriptionId}/certificate`);

function* getCertificate({ payload }) {
  try {
    const response = yield call(getCertificateRequest, payload);
    if (response.status === 200) {
      yield put(getCertificateSuccess());
      window.open(response.data);
    } else {
      yield put(getCertificateError(response.data.message));
      NotificationManager.error(response.data.message, 'Erro');
    }
  } catch (error) {
    yield put(getCertificateError(error));
  }
}
const postEmailNotificationRequest = ({ data }) =>
  backendClient.post('/notification/email', data);

function* postEmailNotification({ payload }) {
  const { callback } = payload;
  try {
    const response = yield call(postEmailNotificationRequest, payload);

    if (response.status === 200) {
      yield put(postEmailNotificationSuccess());
      NotificationManager.success(
        response.data.message || 'Mensagem enviada com sucesso',
        'Sucesso'
      );
      if (callback) {
        callback.success();
      }
    } else {
      yield put(postEmailNotificationError(response.data.message));
      NotificationManager.error(response.data.message, 'Erro');
      if (callback) {
        callback.fail();
      }
    }
  } catch (error) {
    yield put(postEmailNotificationError(error));
    NotificationManager.error(error, 'Erro');
  }
}

export function* watchGetCertificate() {
  yield takeEvery(GET_CERTIFICATE, getCertificate);
}
export function* watchPostEmailNotification() {
  yield takeEvery(POST_EMAIL_NOTIFICATION, postEmailNotification);
}

export default function* rootSaga() {
  yield all([
    fork(watchPatchSubscription),
    fork(watchGetSubscriber),
    fork(watchUpdateWatchedTime),
    fork(watchGetSubscriptionToken),
    fork(watchCreateSubscription),
    fork(watchPostSubscriptionsFile),
    fork(watchIsSubscriberAuthorized),
    fork(watchGetSubscription),
    fork(watchDeleteSubscription),
    fork(watchApprovePayment),
    fork(watchPaySubscription),
    fork(watchPagarmeGetPublicKey),
    fork(watchPatchChangeAccountRole),
    fork(watchGetCertificate),
    fork(watchPostEmailNotification),
  ]);
}
