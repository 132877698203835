import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_USER_PROFILE, UPDATE_USER } from '../actions';
import {
  getUserProfileError,
  getUserProfileSuccess,
  updateUserError,
  updateUserSuccess,
} from './actions';
import { backendClient } from '../../helpers/http';

const getUserProfileAsync = () =>
  backendClient
    .get('/profile')
    .then((user) => user)
    .catch((error) => error);

function* getUserProfile({ payload }) {
  try {
    const response = yield call(getUserProfileAsync, payload);
    if (response.status === 200) {
      yield put(getUserProfileSuccess(response.data));
    } else {
      yield put(getUserProfileError(response.data.message));
    }
  } catch (error) {
    yield put(getUserProfileError(error));
  }
}

export function* watchGetUserProfile() {
  yield takeEvery(GET_USER_PROFILE, getUserProfile);
}

const updateUserAsync = (payload) =>
  backendClient
    .patch('/profile', payload)
    .then((user) => user)
    .catch((error) => error);

function* updateUser({ payload }) {
  try {
    const response = yield call(updateUserAsync, payload);
    if (response.status === 200) {
      yield put(updateUserSuccess(payload.user));
    } else {
      yield put(updateUserError(response.data.message));
    }
  } catch (error) {
    yield put(updateUserError(error));
  }
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}

export default function* rootSaga() {
  yield all([fork(watchGetUserProfile), fork(watchUpdateUser)]);
}
