import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import webinarApp from './webinar/reducer';
import raffleStore from './raffle/reducer';
import subscription from './subscription/reducer';
import watchApp from './watch/reducer';
import surveyListApp from './surveyList/reducer';

import accountStore from './account/reducer';
import chatMessageStore from './chatMessage/reducer';
import couponStore from './coupon/reducer';
import metricsStore from './metrics/reducer';
import pagarmeStore from './pagarme/reducer';
import paypalStore from './paypal/reducer';
import presenterStore from './presenter/reducer';
import profileStore from './profile/reducer';
import surveyStore from './survey/reducer';

const reducers = combineReducers({
  accountStore,
  chatMessageStore,
  couponStore,
  metricsStore,
  pagarmeStore,
  paypalStore,
  presenterStore,
  profileStore,
  surveyStore,

  menu,
  watchApp,
  raffleStore,
  settings,
  webinarApp,
  subscription,
  surveyListApp,
});

export default reducers;
