import {
  WEBINAR_GET_METRICS,
  WEBINAR_GET_METRICS_SUCCESS,
  WEBINAR_GET_METRICS_ERROR,
  GET_METRICS_WEBINAR_ID,
  GET_METRICS_WEBINAR_ID_SUCCESS,
  GET_METRICS_WEBINAR_ID_ERROR,
} from '../actions';

export const getWebinarMetrics = () => ({
  type: WEBINAR_GET_METRICS,
});
export const getWebinarMetricsSuccess = (data) => ({
  type: WEBINAR_GET_METRICS_SUCCESS,
  payload: data,
});
export const getWebinarMetricsError = (error) => ({
  type: WEBINAR_GET_METRICS_ERROR,
  payload: error,
});

export const getMetricsWebinarId = (webinarId) => ({
  type: GET_METRICS_WEBINAR_ID,
  payload: { webinarId },
});
export const getMetricsWebinarIdSuccess = (data) => ({
  type: GET_METRICS_WEBINAR_ID_SUCCESS,
  payload: data,
});
export const getMetricsWebinarIdError = (error) => ({
  type: GET_METRICS_WEBINAR_ID_ERROR,
  payload: error,
});
