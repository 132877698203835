import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { backendClient } from '../../helpers/http';

import {
  GET_SURVEYS,
  SEND_SURVEY_ANSWER,
  SURVEY_DELETE_QUESTION,
  saveSurvey,
  getSurveyDetailError,
  getSurveysError,
  getSurveysSuccess,
} from '../actions';

import { sendSurveyAnswerError, sendSurveyAnswerSuccess } from './actions';

const postSurveyAnswerRequest = ({ surveyId, index }) =>
  backendClient.post(`/survey/${surveyId}/answer`, { index });

function* postSurveyAnswer({ payload }) {
  try {
    yield call(postSurveyAnswerRequest, payload);
    yield put(sendSurveyAnswerSuccess(payload.surveyId));
  } catch (error) {
    yield put(sendSurveyAnswerError(error));
  }
}

export function* watchPostSurveyAnswer() {
  yield takeEvery(SEND_SURVEY_ANSWER, postSurveyAnswer);
}

const getSurveysRequest = ({ webinarId }) =>
  backendClient.get(`/survey/${webinarId}`);

function* getSurveys({ payload }) {
  try {
    const response = yield call(getSurveysRequest, payload);

    if (response.status === 200) {
      yield put(getSurveysSuccess(response.data));
    } else {
      yield put(getSurveysError(response.data.message));
    }
  } catch (error) {
    yield put(getSurveysError(error));
  }
}

function* deleteQuestion({ payload }) {
  try {
    const { id, survey } = payload;
    const newQuestions = survey.questions.filter((x) => x.id !== id);
    yield put(saveSurvey({ questions: newQuestions }));
  } catch (error) {
    yield put(getSurveyDetailError(error));
  }
}
export function* watchDeleteQuestion() {
  yield takeEvery(SURVEY_DELETE_QUESTION, deleteQuestion);
}

export function* watchGetSurveys() {
  yield takeEvery(GET_SURVEYS, getSurveys);
}

export default function* rootSaga() {
  yield all([
    fork(watchDeleteQuestion),
    fork(watchPostSurveyAnswer),
    fork(watchGetSurveys),
  ]);
}
